<template>
    <div
        v-html="require(`!!html-loader!@icons/${name}.svg`).default" 
        class="fill-current"
        v-once
    ></div>
</template>

<script>
// The idea for this came from here: https://gist.github.com/calebporzio/623c536f7fff77861b8a37ec11a1d3da
    export default {
        props: {
            name: {
                default: '',
                type: String,
                required: true,
            },
        },
        mounted() {
            if (this.$el.firstChild === null) return;
            if (this.$el.firstChild.classList === undefined) return;
            this.$el.firstChild.classList.add(...this.$el.className.split(' '))
            this.$el.outerHTML = this.$el.innerHTML
        },
    }
</script>