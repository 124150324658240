<template>
    <div class="img" :data-loading="loading">
        <img :src="src" :data-src="src" :alt="alt"/>
    </div>
</template>

<script>
const axios = require('axios');
export default {
    data() {
        return {
            src: "", // This needs a default path or it doesn't work
            loading: true,
        }
    },
    props: {
        id: Number,
        alt: String,
        siloData: Object,
        size: String,
    },
    created() {
        this.getImage();
    },
    methods: {
        async getImage() {
            if (this.id === 0) return;
            
            try {
                const result = await axios(this.siloData.urls.getimage + this.id);
                this.src = result.data.media_details.sizes[this.size].source_url;
                // Remove loading variable here
                this.loading = false;
            } catch(err) {
                console.error(err);
                // Remove loading variable here
                this.loading = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@s/_mixins.scss";
@import "@s/_vars.scss";
.img {
    background-color: var(--color-grey);
    img {
        opacity: 1;
        transition: all 0.15s;
    }
    &[data-loading="true"] {
        background-color: transparent;
        img {
            opacity: 0;
        }
    }
}
</style>