var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.siloData.user.id !== 0
    ? _c(
        "div",
        {
          staticClass: "eh eh--home bg-color--dark",
          attrs: { id: "entry-header", "data-appstate": _vm.appState },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "eh__content c c--content--pad r r--justify-space-between r--row-always r--halign-center pad--h v--5",
            },
            [
              _c("div", { staticClass: "eh__content__home-link" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "eh__content__logo" },
                [_c("Icon", { attrs: { name: "kicknic-logo-new" } })],
                1
              ),
              _vm._v(" "),
              _vm._m(0),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "eh__content__logout" }, [
      _c(
        "button",
        {
          attrs: {
            onclick: "uma.logout();return false;",
            "aria-title": "Logout",
          },
        },
        [_c("div", { staticClass: "icon icon--logout" })]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }