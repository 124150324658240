<template>
	<span v-if="active" id="wm" style="display: none;">
		<div ref="wm" class="wm v--40">
			<div class="wm__top v--10">
				<div class="wm__tag">
					<span>Welcome Back!</span>
				</div>
				<TransitionGroup name="fade" tag="div">
					<div v-for="(quote, index) in quotes" :key="index" v-show="(currentQuote === index)" class="quote">
						<h1 class="quote__text type--color--purple" v-html="quote"></h1>
					</div>
				</TransitionGroup>
				<div class="wm__count" v-if="(quotes.length > 1)">
					<span class="wm__count__current" v-text="(currentQuote + 1)"></span>
					<span class="wm__count__sep">of</span>
					<span class="wm__count__full" v-text="quotes.length"></span>
				</div>
			</div>
			<div class="wm__nav r r--justify-center r--halign-center">
				<button class="btn btn--solid" @click="next" v-text="(currentQuote === quotes.length - 1 ? 'Close' : 'Next')"></button>
			</div>
		</div>
	</span>
</template>

<script>
	const axios = require('axios');
	export default {
		props: {
			appState: String,
			siloData: Object,
			active: Boolean,
			module_id: Number,
		},
		data() {
			return {
				hasSeen: false,
				currentQuote: 0,
				quotes: [],
				modalArgs: {
					button: false,
					message_is_element: true,
					message: '.wm',
					additional_classes:'notice-centered',
					onClose: this.onClose,
				},
				messages: {
					special: {
						welcome: "Welcome! You’re taking important steps toward quitting!",
						rush: "We are glad you're back, but PLEASE DON'T RUSH! We recommend doing one session per week. Take some time to practice the skills between sessions!",
					},
					progress: [
						"Remember why you want to quit. You can do this!",
						"One step at a time. You’ve got this!",
						"Make every day count!",
						"Remember quitting takes practice. You can do this!",
						"Think about all the changes big or small you have made so far. Great job!",
						"It only seems impossible until it’s done. Keep going!",
						"Only you can change your life. And you are!",
					]
				}
			}
		},
		watch: {
			active() {
				console.log("welcome modal, watch active");
				if (this.active === true) {
					console.log("welcome modal, watch active is true");
					this.check();
				}
			},
		},
		mounted() {

		},
		methods: {
			// Check to see if we open the modal or not
			check() {
				console.log('welcome modal check');
				// This doesn't work because it loads up home first
				// Would need to figure out some kind of delayed loading
				
				//if (this.hasQueryString() === false) { return; }

				// ! NEED A CHECK HERE TO MAKE SURE THE USER HAS QUOTES TO SHOW AT ALL

				if (this.active === false) { return; }
				//if (this.hasSeen === true) { return; }
				// console.log("welcome modal, check passed");
				
				//this.hasSeen = true;
				this.open();
			},
			// Check to see if the logged-in=true query string exists
			// This doesn't work if the user goes through the login flow pages
			hasQueryString() {
				let urlParams = new URLSearchParams(window.location.search);
				let returned = false;

				if (urlParams.has('logged-in')) {
					if (urlParams.get('logged-in') === 'true') {
						returned = true;
					}
				}

				return returned;
			},
			// Open the modal
			open() {
				// console.log('opening modal');
				// console.log("welcome modal, open");
				// console.log('current module: ' + this.module_id);
				
				
				// Fill quotes array before we open this thing (ideally)
				this.getQuotes();
				

				setTimeout(() => {
					// Don't let the modal open if we don't have quotes
					if (this.quotes.length !== 0) {
						modal.set(this.modalArgs);
					}
				}, 500);
			},
			next() {
				// If we've hit the max quotes, close the modal
				if (this.currentQuote === this.quotes.length - 1) {
					modal.close();

				// Otherwise incriment the quotes count
				} else {
					this.currentQuote += 1;
				}
			},
			onClose() {
				console.log('welcome modal on close');
				this.$emit('moduleWelcomeReset');
				this.quotes = [];
				this.currentQuote = 0;
			},
			getQuotes() {
				let quotesArray = [];

				if (this.siloData.user.stats.can_show_rush_date_message === "true") {
					// Check the quote rush array
					let quoteRush = this.quoteRush();
					if (quoteRush !== null) {
						quotesArray.push(quoteRush);
					}
				}

				// Get the current progress quote, there is only one
				// This allows for the welcome quote as well
				/*
				let currentProgessQuote = this.quoteProgress();
				if (currentProgessQuote !== null) {
					quotesArray.push(currentProgessQuote);
				}
				*/

				// Add the Welcome quote, may need a flag for this one
				// Actually we can just see if we have any in progress and if not then show the welcome message
				let quoteWelcome = this.quoteWelcome();
				if (quoteWelcome != null) {
					quotesArray.push(quoteWelcome);
				} 

				// Add the quote based on the order of the modules
				let currentQuoteInOrder = this.quoteInOrder();
				if (currentQuoteInOrder != null) {
					quotesArray.push(currentQuoteInOrder);
				} 

				//return quotesArray;
				this.quotes = quotesArray;
			},
			// Check to see if we need the welcome quote
			quoteWelcome() {
				let returned = this.messages.special.welcome;

				// See if we have ANY complete and if we do, don't show the welcome
				this.siloData.user.progress.forEach((mod, i) => {
					if (mod.status === 'complete') {
						returned = null;
					}
				});

				// ! TODO: Maybe push up a flag here to say we've seen this

				return returned;
			},
			quoteInOrder() {
				if (this.module_id === null) return;

				// Set the module index based on the module ID
				let index = this.module_id - 1;

				// Check to make sure we haven't already seen the module id message
				if (this.siloData.user.progress[index].message_flag === "true") {
					return;
				}

				// Get the quote based on the module index
				let returned = this.messages.progress[index];

				// Push up a flag to say if we've seen this message
				this.submitHasSeenModuleMessage(this.module_id);

				return returned;

			},
			// You can only return one thing here
			// NOTE: NOT IN USE
			quoteProgress() {
				// This is the welcome message which we show if the user has completed 0 modules
				let returned = this.messages.special.welcome;

				let index = null;

				this.siloData.user.progress.forEach((mod, i) => {
					if (mod.status === 'complete') {
						returned = this.messages.progress[i];
						index = i;
					}
				});

				// Set the flag to show we've seen this particular message
				if (index != null) {
					this.submitHasSeenModuleMessage(index);
				}

				return returned;
			},
			// Get rush quote if conditions are met
			quoteRush() {
				let returned = null;

				let mostRecentModuleCompletedIndex = 0;

				// Figure out what the most recent completed module is
				this.siloData.user.progress.forEach((mod, i) => {
					if (mod.status === 'complete') {
						mostRecentModuleCompletedIndex = i;
					}
				});

				console.log("MOST RECENT COMPLETED INDEX", mostRecentModuleCompletedIndex);

				// Make sure we've done at least 3 modules
				if (mostRecentModuleCompletedIndex >= 2) {
					console.log("COMPLETED THREE OR MODULES");
					// For instance, this could be the third index (module 3)
					let mostRecentCompletedDate = Date.parse(this.siloData.user.progress[mostRecentModuleCompletedIndex].date);

					// That would make this module 1
					let thirdCompletedDate = Date.parse(this.siloData.user.progress[mostRecentModuleCompletedIndex - 2].date);

					let difference = mostRecentCompletedDate - thirdCompletedDate;
					console.log("DATE DIFFERENCE");

					let seven_days = 7 * 24 * 60 * 60 * 1000;

					// If time to complete between the third last module and this one is under seven days
					// Set the rush message
					if (difference < seven_days) {
						console.log("DATE DIFFERENCE IS UNDER SEVEN DAYS, SETTING RUSH MESSAGE");
						returned = this.messages.special.rush;
						// Set the flag to false
						this.submitShowRushMessageFlag();
					}
				}

				return returned;
			},			
			submitShowRushMessageFlag() {
				let data = [
					{
						key: "meta_us_can_show_rush_date_message",
						value: "false"
					}
				];
				axios.post(this.siloData.urls.postuserdata, data).then((result) => {
					//console.log('SUBMIT QUIT DATE SEEN FLAG SUBMITTED', result);
					this.$emit('refreshData');
				});
			},
			// Pass up the flag so that the user doesn't see the same message twice
			submitHasSeenModuleMessage(module_number) {
				let data = [
					{
						key: `meta_m${module_number}_message_flag`,
						value: "true",
					}
				];
				axios.post(this.siloData.urls.postuserdata, data).then((result) => {
					//console.log('SUBMIT QUIT DATE SEEN FLAG SUBMITTED', result);
					this.$emit('refreshData');
				});
			},
			// ! NEED: Has Seen Flag for if they've seen the welcome message 
		},
	}
</script>

<style lang="scss" scoped>
@import "@s/_mixins.scss";
@import "@s/_vars.scss";
.wm__tag {
	> * {
		font-size: rem(14px);
		line-height: lh(14px, 18px);
		letter-spacing: 0.5px;
		font-weight: $weight_bold;
		text-transform: uppercase;
	}
}
.quote__text {
	font-size: rem(28px);
	line-height: lh(28px, 34px);
	letter-spacing: 0.34px;
	font-weight: $weight_bold;
}

.wm__count {
	font-size: rem(12px);
	color: var(--color-purple);
}
</style>