var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "icon-intro" },
    _vm._l(_vm.moduleCount, function (n) {
      return _vm.number === n
        ? _c("div", {
            staticClass: "icon-intro__icon",
            domProps: {
              innerHTML: _vm._s(
                require(`!!html-loader!@icons/icon-module-${n}-i.svg`).default
              ),
            },
          })
        : _vm._e()
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }