<template>
    <div class="kudo">
        <div
            v-html="require(`!!html-loader!@icons/kudo/kudos-${selectedKudo}.svg`).default" 
            class="kudo__icon r r--justify-center r--halign-center"
            ref="kudo"
        ></div>
    </div>
</template>

<script>
import Icon from "@c/Icon.vue";
export default {
    components: {
        Icon,
    },
    data() {
        return {
            kudoCount: 7,
        }
    },
    props: {
        instance: Object,
        active: Boolean,
    }, 
    computed: {
        selectedKudo() {
            return Math.floor(Math.random() * (this.kudoCount - 1 + 1) + 1);
        }
    },
    mounted() {
        this.onActive();
    },
    watch: {
        active() {
            if (this.active === true) {
                this.onActive();
            } else {
                this.$refs.kudo.classList.remove('show');
            }
        }
    },
    methods: {
        onActive() {
            setTimeout(() => {
                this.celebrate();
            }, 250);
        },
        celebrate() {
            if (this.active === false) return;
            this.$refs.kudo.classList.add('show');
        },
    }
}
</script>

<style lang="scss">
@import "@s/_mixins.scss";
@import "@s/_vars.scss";
.kudo__icon {
    //overflow: hidden;
    svg {
        transition: all 1s $easing_outback;
        opacity: 0;
        transform: translateY(40px);
    }
    &.show {
        svg {
            opacity: 1;
            transform: translateY(0);
        }
    }
}
</style>