var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "parent", staticClass: "plans" }, [
    _c(
      "div",
      {
        staticClass:
          "text type--content-styles type--content-margins type--content-links v--25",
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "plans__bottom v--10" },
          [
            _vm.siloData.user.progress[0].status === "complete"
              ? _c("ShowHideSlot", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n                    My Time to Quit\n                "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c("p", [
                              _c("strong", [_vm._v("Your Quit Date Plan:")]),
                            ]),
                            _vm._v(" "),
                            _c("p", [_vm._v("Your Motivations to Quit:")]),
                            _vm._v(" "),
                            _c(
                              "ul",
                              _vm._l(
                                _vm.siloData.user.modules.meta_m1_q1,
                                function (item, index) {
                                  return _c("li", {
                                    key: index,
                                    domProps: { textContent: _vm._s(item) },
                                  })
                                }
                              ),
                              0
                            ),
                            _vm._v(" "),
                            _c("p", [_vm._v("Your Ally:")]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.siloData.user.modules.meta_m1_q8)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", [_vm._v("Your Quit Tips:")]),
                            _vm._v(" "),
                            _c(
                              "ul",
                              _vm._l(
                                _vm.siloData.user.modules.meta_m1_q10,
                                function (item, index) {
                                  return _c("li", {
                                    key: index,
                                    domProps: { textContent: _vm._s(item) },
                                  })
                                }
                              ),
                              0
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    3133394830
                  ),
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.siloData.user.progress[1].status === "complete"
              ? _c("ShowHideSlot", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n                    Cope When I Crave\n                "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c("p", [
                              _c("strong", [
                                _vm._v("Your Coping Strategies Plan:"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("p", [_vm._v("Your Trigger:")]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.siloData.user.modules.meta_m2_q7[0])
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", [_vm._v("Your Coping Strategies:")]),
                            _vm._v(" "),
                            _c(
                              "ul",
                              _vm._l(
                                _vm.siloData.user.modules.meta_m2_q8,
                                function (item, index) {
                                  return _c("li", {
                                    key: index,
                                    domProps: { textContent: _vm._s(item) },
                                  })
                                }
                              ),
                              0
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2209938035
                  ),
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.siloData.user.progress[2].status === "complete"
              ? _c("ShowHideSlot", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n                    I Won't Vape\n                "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c("p", [
                              _c("strong", [
                                _vm._v("Your Refusal Skills Plan:"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("p", [_vm._v("Person:")]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.siloData.user.modules.meta_m3_q8)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", [_vm._v("Situation:")]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                _vm._s(_vm.siloData.user.modules.meta_m3_q9)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", [_vm._v("Assertive Responses:")]),
                            _vm._v(" "),
                            _c("ul", [
                              _c("li", [
                                _vm._v(
                                  _vm._s(_vm.siloData.user.modules.meta_m3_q10)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("li", [
                                _vm._v(
                                  _vm._s(_vm.siloData.user.modules.meta_m3_q11)
                                ),
                              ]),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    421221509
                  ),
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.siloData.user.progress[3].status === "complete"
              ? _c("ShowHideSlot", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n                    Manage My Stress\n                "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c("p", [
                              _c("strong", [
                                _vm._v("Your Deal With Stress Plan:"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("p", [_vm._v("Your Stressors:")]),
                            _vm._v(" "),
                            _c(
                              "ul",
                              _vm._l(
                                _vm.siloData.user.modules.meta_m4_q5,
                                function (item, index) {
                                  return _c("li", {
                                    key: index,
                                    domProps: { textContent: _vm._s(item) },
                                  })
                                }
                              ),
                              0
                            ),
                            _vm._v(" "),
                            _c("p", [_vm._v("What can you do?:")]),
                            _vm._v(" "),
                            _c(
                              "ul",
                              _vm._l(
                                _vm.siloData.user.modules.meta_m4_q7,
                                function (item, index) {
                                  return _c("li", {
                                    key: index,
                                    domProps: { textContent: _vm._s(item) },
                                  })
                                }
                              ),
                              0
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    849893596
                  ),
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.siloData.user.progress[4].status === "complete"
              ? _c("ShowHideSlot", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n                    I Won't React\n                "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c("p", [
                              _c("strong", [
                                _vm._v("Your Negative Mood Plan:"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("p", [_vm._v("Your Triggers:")]),
                            _vm._v(" "),
                            _c(
                              "ul",
                              _vm._l(
                                _vm.siloData.user.modules.meta_m5_q4,
                                function (item, index) {
                                  return _c("li", {
                                    key: index,
                                    domProps: { textContent: _vm._s(item) },
                                  })
                                }
                              ),
                              0
                            ),
                            _vm._v(" "),
                            _c("p", [_vm._v("What can you say to yourself?:")]),
                            _vm._v(" "),
                            _c(
                              "ul",
                              _vm._l(
                                _vm.siloData.user.modules.meta_m5_q5,
                                function (item, index) {
                                  return _c("li", {
                                    key: index,
                                    domProps: { textContent: _vm._s(item) },
                                  })
                                }
                              ),
                              0
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    854071776
                  ),
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.siloData.user.progress[5].status === "complete"
              ? _c("ShowHideSlot", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n                    I'll Think Then Act\n                "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c("p", [
                              _c("strong", [
                                _vm._v("Your Your Stop to Think Plan:"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("p", [_vm._v("Your Routine or Habit")]),
                            _vm._v(" "),
                            _c("p", [_vm._v("Situation")]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.siloData.user.modules.meta_m6_q4) +
                                  "\n                    "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", [_vm._v("Stop to Think")]),
                            _vm._v(" "),
                            _c(
                              "ul",
                              _vm._l(
                                _vm.siloData.user.modules.meta_m6_q8,
                                function (item, index) {
                                  return _c("li", {
                                    key: index,
                                    domProps: { textContent: _vm._s(item) },
                                  })
                                }
                              ),
                              0
                            ),
                            _vm._v(" "),
                            _c("p", [_vm._v("In The Moment")]),
                            _vm._v(" "),
                            _c("p", [_vm._v("Situation")]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.siloData.user.modules.meta_m6_q5) +
                                  "\n                    "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", [_vm._v("Stop to Think")]),
                            _vm._v(" "),
                            _c(
                              "ul",
                              _vm._l(
                                _vm.siloData.user.modules.meta_m6_q9,
                                function (item, index) {
                                  return _c("li", {
                                    key: index,
                                    domProps: { textContent: _vm._s(item) },
                                  })
                                }
                              ),
                              0
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2717476684
                  ),
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.siloData.user.progress[6].status === "complete"
              ? _c("ShowHideSlot", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n                    I Commit to Quit\n                "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "content",
                        fn: function () {
                          return [
                            _c("p", [
                              _c("strong", [
                                _vm._v("Your Plan for the Future:"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("p", [_vm._v("Your Triggers:")]),
                            _vm._v(" "),
                            _c(
                              "ul",
                              _vm._l(
                                _vm.siloData.user.modules.meta_m7_q9,
                                function (item, index) {
                                  return _c("li", {
                                    key: index,
                                    domProps: { textContent: _vm._s(item) },
                                  })
                                }
                              ),
                              0
                            ),
                            _vm._v(" "),
                            _c("p", [_vm._v("Your Coping Strategies:")]),
                            _vm._v(" "),
                            _c(
                              "ul",
                              _vm._l(
                                _vm.siloData.user.modules.meta_m7_q10,
                                function (item, index) {
                                  return _c("li", {
                                    key: index,
                                    domProps: { textContent: _vm._s(item) },
                                  })
                                }
                              ),
                              0
                            ),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v("Your Challenge to Red Flag Thoughts:"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "ul",
                              _vm._l(
                                _vm.siloData.user.modules.meta_m7_q8,
                                function (item, index) {
                                  return _c("li", {
                                    key: index,
                                    domProps: { textContent: _vm._s(item) },
                                  })
                                }
                              ),
                              0
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    1511250532
                  ),
                })
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "plans__top" }, [
      _c(
        "h2",
        {
          staticClass:
            "text__title header icon icon--plan r r--row-always r--halign-center",
        },
        [_vm._v("\n                My Plan\n            ")]
      ),
      _vm._v(" "),
      _c("p", [
        _vm._v("Here are the plans you created at the end of each session."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }