<template>
    <div ref="parent" class="tasks">
        <div class="text type--content-styles type--content-margins type--content-links v--25">
            <div class="tasks__top">
                <h2 class="header text__title icon icon--tasks r r--row-always r--halign-center">
                    My Tasks
                </h2>
                <p>Each session will ask if you practiced your plan from the previous session. If you did, great! It will automatically
                check off here. If not, then you can always come here and check it off once you complete it.</p>
            </div>

            <form 
                ref="form"
                action="" 
                method="post" 
                class="form form--v required v--50">
                <div class="form__fields r">
                    <div class="input input--buttons v--25">
                        <div class="input input--checkboxes v--25">

                            <div class="field__checkbox-group c c--content--pad">

                                <div class="field__options">

                                    <div v-for="(task, index) in tasks" v-if="task.ready" :key="index" class="option">
                                        <input
                                            class="checkbox"
                                            type="checkbox"
                                            :id="`task-${index}`"
                                            :name="`task-${index}-field`"
                                            value="1"
                                            :data-label="task.label"
                                            :data-push-key="task.pushKey"
                                            @change="submit($event)"
                                            :checked="task.isChecked"
                                            />
                                        <label :for="`task-${index}`">
                                            <span class="type--option" v-text="task.label"></span>
                                        </label>
                                    </div>


                                </div>
                            </div>    
                        </div>
                    </div>
                </div>
            </form>

        </div>
    </div>
</template>

<script>
// https://github.com/JoshuaKGoldberg/emojisplosion
import { emojisplosion } from "emojisplosion";
const axios = require('axios');

export default {
    components: {
    },
    data() {
        return {
        }
    },
    props: {
        active: Boolean,
        siloData: Object,
        appState: String,
    },
    computed: {
        inputs() {
            return this.$refs.parent.querySelectorAll('.checkbox');
        },
        tasks() {
            return [
                {
                    label: 'Talked to my ally',
                    ready: this.siloData.user.modules.meta_m2_q1 !== '' ? true : false,
                    isChecked: this.siloData.user.modules.meta_m2_q1 === "Yes" ? true : false,
                    pushKey: 'meta_m2_q1',
                },
                {
                    label: 'Used my Quit Tips to prepare for my Quit Date',
                    ready: this.siloData.user.modules.meta_m2_q2 !== '' ? true : false,
                    isChecked: this.siloData.user.modules.meta_m2_q2 === "Yes" ? true : false,
                    pushKey: 'meta_m2_q2',
                },
                {
                    label: 'Used my Coping Strategies Plan to cope with triggers without vaping',
                    ready: this.siloData.user.modules.meta_m3_q2 !== '' ? true : false,
                    isChecked: this.siloData.user.modules.meta_m3_q2 === "Yes" ? true : false,
                    pushKey: 'meta_m3_q2',
                },
                {
                    label: 'Used my Refusal Skills Plan to assertively refuse an offer to vape',
                    ready: this.siloData.user.modules.meta_m4_q1 !== '' ? true : false,
                    isChecked: this.siloData.user.modules.meta_m4_q1 === "Yes" ? true : false,
                    pushKey: 'meta_m4_q1',
                },
                {
                    label: 'Used my Deal With Stress Plan to cope with stress without vaping',
                    ready: this.siloData.user.modules.meta_m5_q1 !== '' ? true : false,
                    isChecked: this.siloData.user.modules.meta_m5_q1 === "Yes" ? true : false,
                    pushKey: 'meta_m5_q1',
                },
                {
                    label: 'Used my Negative Mood Plan to cope with a negative mood without vaping',
                    ready: this.siloData.user.modules.meta_m6_q1 !== '' ? true : false,
                    isChecked: this.siloData.user.modules.meta_m6_q1 === "Yes" ? true : false,
                    pushKey: 'meta_m6_q1',
                },
                {
                    label: 'Used my Stop to Think Plan to change a habit or routine that kept you away from vaping',
                    ready: this.siloData.user.modules.meta_m7_q1 !== '' ? true : false,
                    isChecked: this.siloData.user.modules.meta_m7_q1 === "Yes" ? true : false,
                    pushKey: 'meta_m7_q1',
                },
                {
                    label: 'Used my Stop to Think Plan when you were in a situation where you were offered a vape or had the urge to vape',
                    ready: this.siloData.user.modules.meta_m7_q4 !== '' ? true : false,
                    isChecked: this.siloData.user.modules.meta_m7_q4 === "Yes" ? true : false,
                    pushKey: 'meta_m7_q4',
                },
            ]
        },
    },
    watch: {
        active() {
            if (this.active === true) {
                this.postPageView();
            }
        }
    },
    mounted() {
        this.submit();
    },
    methods: {
        submit(event = null) {

            if (event !== null) {
                // Run the task check so we can push an event
                this.postTaskChange(event.target.dataset.label, event.target.checked);
            }

            let data = [];

            this.inputs.forEach(input => {

                let key = input.dataset.pushKey;
                let value = null;

                if (input.checked === true) {
                    value = 'Yes';
                } else {
                    value = 'No';
                }

                let data_item = {
                    key,
                    value
                }
                data.push(data_item);
            });

            console.log("FORMS: DATA", data);
            
            // celebrate and pass box coordinates
            if (event !== null && event.target.checked === true) {
                this.celebrate(event.target);
            }

            // TODO: Make sure this component passes this emit event up correctly
            this.$emit('processing', true);

            axios.post(this.siloData.urls.postuserdata, data).then(response => {
                console.log(response);

                // When the data processing is complete, fire next
                this.$emit('refreshData', this.onComplete);
                // TODO: Maybe some feedback here
            }).catch(err => {
                console.log(err);
                // Turn off processing
                this.$emit('processing', false);
            });
        },
        onComplete() {
            // Turn off processing
            this.$emit('processing', false);
        },
        celebrate(input) {

            let bounds = input.getBoundingClientRect();

            emojisplosion(
                {
                    //emojis: ["✅", "🎉", "⭐️"],
                    emojis: ["🥳", "🎉", "⭐️"],
                    //emojis: ["great", "ok", "yes", "excellent"],

                    emojiCount: 10,
                    // Getting center of object
                    position: {
                        x: bounds.left + input.width / 2,
                        y: bounds.top + input.height / 2,
                    }
                }
            )
        },
        postPageView() {
            let data = {
                type: 'task',
                title: 'My Tasks',
            };
            window.events.postPageView(data);
        },
        postTaskChange(title, setting) {
            let data = {
                type: 'task',
                title: title,
                setting: setting,
            };
            window.events.postAction(data); 
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@s/_mixins.scss";
@import "@s/_vars.scss";
.tasks {
    padding-bottom: rem(90px);
}
form {
    transition: all 0.15s;
}

[data-processing="true"] {
    form {
        opacity: 0.25;
    }
    input {
        pointer-events: none;
    }
}
</style>