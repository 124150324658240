<template>
    <div v-if="siloData.user.id !== 0" v-show="appState === 'home'" id="modules" class="modules c pad--h">
        <!-- {{modules}} -->


        <div v-for="(mod, index) in modules" :key="index" class="modules__module r" :class="getEvenOrOdd(index)" :data-module="(index + 1)" :data-status="getStatus(index)" :data-locked="isLocked(index)" :data-is-first="(index===0?'true':'false')">
            <button @click="openModule(mod.ID)" class="modules__module__button r r--halign-center" :title="mod.ID">
                
                
                <div class="modules__module__button__wrap r r--halign-center v--10">
                    <div class="modules__module__button__icons">
                        <div class="modules__module__button__icons__icon r r--justify-center r--halign-center">
                            <Icon :name="getModuleIconName(mod.order)" />
                            <div class="modules__module__button__icons__icon__ring r r--justify-center r--halign-center">
                                <div class="modules__module__button__icons__icon__ring__inner"></div>
                                <div class="modules__module__button__icons__icon__ring__outer"></div>
                            </div>
                        </div>
                        <div class="modules__module__button__icons__status r r--justify-center r--halign-center">
                            <div class="module__status module__status--locked r r--halign-center">
                                <Icon name="icon-lock" />
                            </div>
                            <div class="module__status module__status--complete r r--halign-center">
                                <Icon name="icon-complete" />
                            </div>
                        </div>
                    </div>
                    <h3 class="modules__module__button__text"><span class="r r--justify-center">{{mod.title}}</span></h3>
                </div>


            </button> 
            <div v-if="index !== modules.length - 1" class="modules__module__arrow">
                <Icon name="arrow-dash" />
            </div>
        </div>

    </div>
</template>

<script>
import Icon from "@c/Icon.vue"
const axios = require('axios');
export default {
    components: {
        Icon,
    },
    data() {
        return {
            modules: null,
        }
    },
    props: {
		appState: String,
		siloData: Object,
	},
    computed: {

    },
    mounted() {
        this.getModules();
    },
    watch: {
        // Determine status of module here
        appState() {
            if (this.siloData.user.id !== 0 && this.appState === 'home') {
                this.postPageView();
            }
        }
    },
    methods: {
        postPageView() {
            let data = {
                type: 'home'
            };
            window.events.postPageView(data);
        },
        getEvenOrOdd(index) {
            return index % 2 === 0 ? "odd" : "even";
        },
        getStatus(index) {
            // take the index, which will be the module number
            // based on that, get look for the coorelated index in siloData.user.
            return this.siloData.user.progress[index].status ? this.siloData.user.progress[index].status : "open";
        },
        isLocked(index) {
            // By default each module is locked
            // let returned = "true";

            // If this is the first module unlock it
            if (index === 0) {
                return "false";
            } else {
                // If this is a module greater than 1
                // Look at the user's status table
                // and check the previous index to see if it is completed
                let previous_module_status = this.siloData.user.progress[index - 1].status;

                // If the previous module is complete, this module is unlocked
                if (previous_module_status === "complete") {
                    return "false"
                } else {
                    return "true"
                }
            }

            //return returned;
        },
        getModules() {
            axios(this.siloData.urls.getmodules).then((result) => {
                console.log(result);
                this.modules = result.data;
            }).catch((reason) => {
                console.log(reason);
            });
        },
        getModuleIconName(order) {
            return "icon-module-" + order;
        },
        // There's definitly a better way to do this
        openModule(id) {
            // We need to clear out any query strings, otherwise when we open the URL
            // it will make the modal act strange
            this.clearQueryStrings();
            window.moduleloader.open(id);
        },
        clearQueryStrings() {
            // Get the current url without query strings
            let cleanUrl = window.location.href.replace(window.location.search, '');
            // Update the URL without reloading
            window.history.replaceState({}, window.title, cleanUrl);
        },
    },
}
</script>

<style lang="scss">
@import "@s/_mixins.scss";
@import "@s/_vars.scss";

$icon_width: 66px;
$icon_ring_width: 86px;
$icon_ring_inner_width: 76px;
$icon_container_max: 110px;
$column_width: 340px;
$module_width: 130px;
$text_width: $module_width;
$status_icon_width: 30px;
$status_icon_width_half: $status_icon_width / 2;

.modules {
    max-width: rem($column_width);
    padding-top: rem(25px);
    padding-bottom: var(--height--toolbelt);
    margin-bottom: var(--height--toolbelt);
    >*:nth-child(even) {
        .modules__module__button {
            //flex-direction: row-reverse;
            //text-align: right;
            //justify-content: flex-end;
            svg {
                //margin-left: auto;
            }
            .modules__module__button__text {
                //justify-content: flex-end;
                //padding-right: 20px;
            }
        }
    }
    >*:nth-child(odd) {
        .modules__module__button {
            // justify-content: flex-start;
            .modules__module__button__text {        
                span {
                    // margin-left: rem(20px);
                }
            }
        }
    }
}
.modules__module {
    svg {
        width: rem($icon_width);
        height: rem($icon_width);
        display: flex;
        flex: 0 0 auto;
        transition: all 0.25s $easing_regular;
    }
    &[data-locked="true"] {
        pointer-events:none;
        //opacity: 0.5;
        .modules__module__button__icons__icon,
        .modules__module__button__text {
            opacity: 0.7;
        }
    }
    &[data-locked="false"] {
        pointer-events:inherit;
    }
    &:not([data-is-first='true']) {
        .modules__module__button {
            margin-top: rem(-46px);
        }
    }
}
.modules__module__button {
    transition: all 0.15s;
    width: rem($module_width);
    &:hover, &:focus {
        .modules__module__button__text {
        }
        .modules__module__button__icons__icon {
            svg {
                transform: scale(1.16);
            }
        }
    }
}
[data-processing="true"] .modules__module__button{
    opacity:0.25;
    pointer-events: none;
}
// Next arrow
.modules__module__arrow {
    position: absolute;
    bottom: 30px;
    width: 40px;
    height: 25px;
    pointer-events: none;
    opacity: 0;
    overflow: hidden;
    >* {
        width: 60px !important;
        height: auto !important;
        position: absolute;
    }
}
// Holder for the status and the svg
.modules__module__button__icons {
    width: rem($icon_container_max);
    svg {
        z-index: 1;
    }
}

// Container for the main icon
.modules__module__button__icons__icon {
    height: rem($icon_ring_width);
    svg {
        
    }
}

// positioner for the ring
.modules__module__button__icons__icon__ring {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0 0 0 0;
}

// white part in the middle of the ring
.modules__module__button__icons__icon__ring__inner {
    display: block;
    width: rem($icon_ring_inner_width);
    height: rem($icon_ring_inner_width);
    border-radius: 100%;
    background-color: var(--color-white);
    position: absolute;
    z-index: 0;
}

// radial outer part of the ring
.modules__module__button__icons__icon__ring__outer {
    display: block;
    width: rem($icon_ring_width);
    height: rem($icon_ring_width);
    border-radius: 100%;
    //background-color: blue;
    position: absolute;
    z-index: -1;
}

// Status icon, eg. locked or completed
.modules__module__button__icons__status {
    position: absolute;
    top: calc(50% - #{$status_icon_width_half});
    width: rem($status_icon_width);
    height: rem($status_icon_width);
    z-index: 2;
}
.module__status {
    width: 100%;
    height: auto;
    position: absolute;
    opacity: 0;
    transition: all 0.15s;
    > * {
        width: 100% !important;
        height: auto !important;
    }
}
.modules__module {
    &.odd {
        align-items: flex-start;
        .modules__module__button__icons__status {
            //left: -$status_icon_width_half;
        }
        .modules__module__arrow {
            left: 117px;
            svg {
                right: 0;
            }
        }
    }
    &.even {
        align-items: flex-end;
        .modules__module__button__icons__status {
            right: 0;
        }
        .modules__module__arrow {
            // Mirror the image in the other direction
            
            right: 117px;
            svg {
                transform: scale(-1, 1);
                left: 0;
                bottom: 0;
            }
        }
    }
    &[data-locked="true"] {
        .module__status--locked {
            opacity: 1;
            svg {
                width: 22px !important;
                height: 26px !important;
            }
        }
    }
    &[data-status="complete"] {
        .module__status--complete {
            opacity: 1;
            svg {
                // This SVG is a little small
                transform: scale(1.25);
            }
        }
        .modules__module__arrow {
            opacity: 1;
        }
    }
}
.modules__module__button__text {
    text-align: center;
    max-width: rem($text_width);
    width: 100%;
    display: flex;
    flex: 1 1 auto;
    font-size: rem(14px);
    line-height: lh(14px, 17px);
    font-weight: $weight_bold;
    letter-spacing: -0.22px;
}

// THEMING

.modules__module {
    &[data-module="1"] {
        .modules__module__button__icons__icon__ring__outer {
            background-image: linear-gradient(to bottom, var(--color-m1-dark), var(--color-m1-light) 90%);
        }
        .modules__module__button {
            &:hover, &:focus {
                .modules__module__button__text {
                    color: var(--color-m1-light);
                }
            }
        }
    }
    &[data-module="2"] {
        .modules__module__button__icons__icon__ring__outer {
            background-image: linear-gradient(to bottom, var(--color-m2-dark), var(--color-m2-light) 90%);
        }
        .modules__module__button {
            &:hover, &:focus {
                .modules__module__button__text {
                    color: var(--color-m2-light);
                }
            }
        }
    }
    &[data-module="3"] {
        .modules__module__button__icons__icon__ring__outer {
            background-image: linear-gradient(to bottom, var(--color-m3-dark), var(--color-m3-light) 90%);
        }
        .modules__module__button {
            &:hover, &:focus {
                .modules__module__button__text {
                    color: var(--color-m3-light);
                }
            }
        }
    }
    &[data-module="4"] {
        .modules__module__button__icons__icon__ring__outer {
            background-image: linear-gradient(to bottom, var(--color-m4-dark), var(--color-m4-light) 90%);
        }
        .modules__module__button {
            &:hover, &:focus {
                .modules__module__button__text {
                    color: var(--color-m4-light);
                }
            }
        }
    }
    &[data-module="5"] {
        .modules__module__button__icons__icon__ring__outer {
            background-image: linear-gradient(to bottom, var(--color-m5-dark), var(--color-m5-light) 90%);
        }
        .modules__module__button {
            &:hover, &:focus {
                .modules__module__button__text {
                    color: var(--color-m5-light);
                }
            }
        }
    }
    &[data-module="6"] {
        .modules__module__button__icons__icon__ring__outer {
            background-image: linear-gradient(to bottom, var(--color-m6-dark), var(--color-m6-light) 90%);
        }
        .modules__module__button {
            &:hover, &:focus {
                .modules__module__button__text {
                    color: var(--color-m6-light);
                }
            }
        }
    }
    &[data-module="7"] {
        .modules__module__button__icons__icon__ring__outer {
            background-image: linear-gradient(to bottom, var(--color-m7-dark), var(--color-m7-light) 90%);
        }
        .modules__module__button {
            &:hover, &:focus {
                .modules__module__button__text {
                    color: var(--color-m7-light);
                }
            }
        }
    }
}
</style>