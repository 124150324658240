var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "login v--40",
      class: _vm.form_class,
      attrs: { "data-state": _vm.form_state },
    },
    [
      _c("div", { staticClass: "eh eh--login bg-color--dark" }, [
        _c(
          "div",
          { staticClass: "eh__content c c--content--pad pad--h v--5" },
          [
            _c("div", { staticClass: "eh__meta type--small" }, [
              _vm._v("Welcome to"),
            ]),
            _vm._v(" "),
            _c(
              "h1",
              { staticClass: "eh__title type--h1 change" },
              [_c("Icon", { attrs: { name: "kicknic-logo-new" } })],
              1
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "login__row" }, [
        _c("div", { staticClass: "login__form c c--narrow bg-color--cream" }, [
          _c("div", { staticClass: "login__welcome pad--h" }, [
            _c("h2", {
              staticClass: "notice__message type--h2",
              domProps: { innerHTML: _vm._s(_vm.current_notice) },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "login__form__row r" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "form",
              {
                staticClass: "form pad--h",
                attrs: {
                  name: "loginform",
                  id: "loginform",
                  action: _vm.action,
                  method: "post",
                },
              },
              [
                _c("div", { staticClass: "login__form__body" }, [
                  _c("ul", { staticClass: "form__body__fields v--50" }, [
                    _c("li", { staticClass: "field validate--empty r" }, [
                      _c(
                        "label",
                        {
                          staticClass: "type--label",
                          attrs: { for: "user_login" },
                        },
                        [_vm._v("User Name")]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.username,
                            expression: "username",
                          },
                        ],
                        attrs: {
                          name: "log",
                          id: "user_login",
                          type: "text",
                          autocomplete: "username",
                        },
                        domProps: { value: _vm.username },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.username = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "message" }),
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "field validate--empty r" }, [
                      _c(
                        "label",
                        {
                          staticClass: "type--label",
                          attrs: { for: "user_pass" },
                        },
                        [_vm._v("Password")]
                      ),
                      _vm._v(" "),
                      _c("span", [
                        _vm.password_input_type === "checkbox"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.password,
                                  expression: "password",
                                },
                              ],
                              attrs: {
                                name: "pwd",
                                id: "user_pass",
                                autocomplete: "current-password",
                                type: "checkbox",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.password)
                                  ? _vm._i(_vm.password, null) > -1
                                  : _vm.password,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.password,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.password = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.password = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.password = $$c
                                  }
                                },
                              },
                            })
                          : _vm.password_input_type === "radio"
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.password,
                                  expression: "password",
                                },
                              ],
                              attrs: {
                                name: "pwd",
                                id: "user_pass",
                                autocomplete: "current-password",
                                type: "radio",
                              },
                              domProps: { checked: _vm._q(_vm.password, null) },
                              on: {
                                change: function ($event) {
                                  _vm.password = null
                                },
                              },
                            })
                          : _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.password,
                                  expression: "password",
                                },
                              ],
                              attrs: {
                                name: "pwd",
                                id: "user_pass",
                                autocomplete: "current-password",
                                type: _vm.password_input_type,
                              },
                              domProps: { value: _vm.password },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.password = $event.target.value
                                },
                              },
                            }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "svg show-password",
                            attrs: {
                              href: "#",
                              "data-state": _vm.password_showing_state,
                              "aria-label": "Show Password",
                            },
                            on: { click: _vm.toggle_show_password },
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "15",
                                  height: "15",
                                  viewBox: "0 0 15 15",
                                  xmlns: "http://www.w3.org/2000/svg",
                                  "xmlns:xlink": "http://www.w3.org/1999/xlink",
                                },
                              },
                              [
                                _c(
                                  "g",
                                  {
                                    attrs: {
                                      stroke: "#000",
                                      "stroke-width": "1.4",
                                      fill: "none",
                                      "fill-rule": "evenodd",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        d: "M11.55 11.55a6.866 6.866 0 0 1-4.05 1.405C2.727 12.955 0 7.5 0 7.5a12.58 12.58 0 0 1 3.45-4.05m2.618-1.24c.47-.11.95-.166 1.432-.165C12.273 2.045 15 7.5 15 7.5a12.614 12.614 0 0 1-1.473 2.175m-4.582-.73a2.045 2.045 0 1 1-2.89-2.89",
                                      },
                                    }),
                                    _c("path", {
                                      staticClass: "cross",
                                      attrs: { d: "m0 0 15 15" },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "message" }),
                    ]),
                    _vm._v(" "),
                    _vm.silo_data.messages.pw_assistance
                      ? _c(
                          "li",
                          {
                            staticClass:
                              "field login__form__note type--align-right",
                          },
                          [
                            _c("span", [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "type--comment type--color--purple",
                                  attrs: { href: "#" },
                                  on: { click: _vm.open_modal_help },
                                },
                                [_vm._v("Need Help Logging-In?")]
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "login__form__footer pad--h" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "login__form__footer__wrapper r r--halign-center r--valign-center",
                    },
                    [
                      _c("input", {
                        staticClass: "btn btn--solid btn--purple",
                        attrs: {
                          name: "wp-submit",
                          id: "wp-submit",
                          type: "submit",
                          "aria-label": "Log In",
                          value: "Log In",
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.login.apply(null, arguments)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.current_explaination
                        ? _c("div", { staticClass: "explaination" }, [
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(_vm.current_explaination),
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("input", {
                        attrs: { type: "hidden", name: "redirect_to" },
                        domProps: {
                          value: this.silo_data.urls.home + "?logged-in=true",
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        attrs: {
                          type: "hidden",
                          name: "testcookie",
                          value: "1",
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        attrs: { type: "hidden", name: "login", value: "true" },
                      }),
                      _vm._v(" "),
                      _vm.nonce
                        ? _c("input", {
                            attrs: {
                              type: "hidden",
                              id: "security",
                              name: "security",
                            },
                            domProps: { value: _vm.nonce },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("input", {
                        attrs: {
                          type: "hidden",
                          name: "_wp_http_referer",
                          value: "/login",
                        },
                      }),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "login__version r r--justify-center type--align-center pad--h",
            },
            [_c("span", [_vm._v(_vm._s(_vm.version))])]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "notice type--dark-background" }, [
      _c("div", { staticClass: "notice__container" }, [
        _c("div", { staticClass: "notice__message type--align-center pad--h" }),
        _vm._v(" "),
        _c("div", { staticClass: "notice__loading" }, [
          _c("div", { staticClass: "spinner-box" }, [
            _c("div", { staticClass: "pulse-container" }, [
              _c("div", { staticClass: "pulse-bubble pulse-bubble-1" }),
              _vm._v(" "),
              _c("div", { staticClass: "pulse-bubble pulse-bubble-2" }),
              _vm._v(" "),
              _c("div", { staticClass: "pulse-bubble pulse-bubble-3" }),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }