var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vm-holder", staticStyle: { display: "none" } },
    [
      _c(
        "div",
        {
          ref: "outer",
          staticClass: "vm anim",
          attrs: { id: "vm", "aria-hidden": "true" },
        },
        [
          _c("div", { staticClass: "vm__content__container" }, [
            _c(
              "div",
              {
                ref: "parent",
                staticClass: "embed-container unready",
                attrs: { id: "vm__video-player", width: "1280", height: "720" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "vm__video-player__loading",
                    attrs: { width: "1280", height: "720" },
                  },
                  [_c("Icon", { attrs: { name: "kicknic-logo-new" } })],
                  1
                ),
                _vm._v(" "),
                _c("div", {
                  ref: "parent_video",
                  staticClass:
                    "video-player video-player--vimeo aspect-ratio--video",
                  attrs: { id: "video-player-vimeo", "aria-hidden": "true" },
                }),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }