var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.siloData.user.id !== 0
    ? _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.appState === "home",
              expression: "appState === 'home'",
            },
          ],
          staticClass: "modules c pad--h",
          attrs: { id: "modules" },
        },
        _vm._l(_vm.modules, function (mod, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "modules__module r",
              class: _vm.getEvenOrOdd(index),
              attrs: {
                "data-module": index + 1,
                "data-status": _vm.getStatus(index),
                "data-locked": _vm.isLocked(index),
                "data-is-first": index === 0 ? "true" : "false",
              },
            },
            [
              _c(
                "button",
                {
                  staticClass: "modules__module__button r r--halign-center",
                  attrs: { title: mod.ID },
                  on: {
                    click: function ($event) {
                      return _vm.openModule(mod.ID)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "modules__module__button__wrap r r--halign-center v--10",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "modules__module__button__icons" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "modules__module__button__icons__icon r r--justify-center r--halign-center",
                            },
                            [
                              _c("Icon", {
                                attrs: {
                                  name: _vm.getModuleIconName(mod.order),
                                },
                              }),
                              _vm._v(" "),
                              _vm._m(0, true),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "modules__module__button__icons__status r r--justify-center r--halign-center",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "module__status module__status--locked r r--halign-center",
                                },
                                [_c("Icon", { attrs: { name: "icon-lock" } })],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "module__status module__status--complete r r--halign-center",
                                },
                                [
                                  _c("Icon", {
                                    attrs: { name: "icon-complete" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "h3",
                        { staticClass: "modules__module__button__text" },
                        [
                          _c("span", { staticClass: "r r--justify-center" }, [
                            _vm._v(_vm._s(mod.title)),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              index !== _vm.modules.length - 1
                ? _c(
                    "div",
                    { staticClass: "modules__module__arrow" },
                    [_c("Icon", { attrs: { name: "arrow-dash" } })],
                    1
                  )
                : _vm._e(),
            ]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "modules__module__button__icons__icon__ring r r--justify-center r--halign-center",
      },
      [
        _c("div", {
          staticClass: "modules__module__button__icons__icon__ring__inner",
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "modules__module__button__icons__icon__ring__outer",
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }