var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input input--date v--25" }, [
    _c("label", {
      class: _vm.hideLabel,
      domProps: { innerHTML: _vm._s(_vm.field.field_label) },
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "field__date c c--content--pad" },
      [
        _c("v-calendar", {
          attrs: {
            attributes: _vm.attributes,
            timezone: _vm.siloData.user.tz,
            "min-date": _vm.minDate,
            "max-date": _vm.maxDate,
          },
          on: { dayclick: _vm.dayClicked },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticStyle: { display: "none" } }, [
      _c("input", {
        attrs: {
          type: "text",
          "data-push-key": _vm.field.push_key,
          "data-watch-event": "change",
          "data-pristine-not-blank": "",
          required: "",
        },
        domProps: { value: _vm.days },
        on: {
          change: function ($event) {
            return _vm.$emit("validate")
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticStyle: { display: "none" } }, [
      _c("div", { ref: "warning", staticClass: "warning-notice" }, [_vm._m(0)]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "r v--20" }, [
      _c(
        "div",
        {
          staticClass:
            "warning__copy type--content-margins type--content-styles",
        },
        [
          _c("h2", { staticClass: "type--h2 type--color--purple" }, [
            _vm._v("Please Note"),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Choosing a date less than a week away will give you less time to prepare. Consider choosing a date over one week away."
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "warning__buttons r r--halign-center r--valign-center r--justify-center v--15",
        },
        [
          _c(
            "button",
            {
              staticClass: "ms__next btn btn--solid",
              attrs: { onclick: "modal.close()" },
            },
            [_vm._v("Ok")]
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }