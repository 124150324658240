var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.siloData.user.id !== 0
    ? _c(
        "span",
        { staticStyle: { display: "none" }, attrs: { id: "vapetracker" } },
        [
          _c(
            "div",
            {
              staticClass: "vapetracker c c--content--pad r",
              attrs: {
                "data-state": _vm.state,
                "data-processing": _vm.processing,
              },
            },
            [
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.state === "ready"
                  ? _c("div", [
                      _vm.quitDate !== ""
                        ? _c("div", { staticClass: "v--15" }, [
                            _c("div", {
                              staticClass: "vapetracker__copy",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.siloData.messages.vape_calendar_copy
                                ),
                              },
                            }),
                            _vm._v(" "),
                            _vm.siloData.user.modules.meta_vt_target_date
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vapetracker__quitedate type--vt--small type--align-center",
                                  },
                                  [
                                    _vm._v("\n\t\t\t\t\t\tYour Quit Date: "),
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(_vm.quitDate),
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vapetracker__calendar type--align-center",
                              },
                              [
                                _c("v-calendar", {
                                  attrs: {
                                    attributes: _vm.attributes(),
                                    timezone: _vm.siloData.user.tz,
                                    "max-date": new Date(),
                                    "is-expanded": "",
                                  },
                                  on: { dayclick: _vm.dayClicked },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vapetracker__count type--vt--small type--align-center",
                              },
                              [
                                _c("h5", [
                                  _vm._v(
                                    "Total Vape-Free Days: " +
                                      _vm._s(_vm.days.length)
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vapetracker__footer type--align-center",
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn--solid",
                                    attrs: { disabled: _vm.buttonDisabled },
                                    on: { click: _vm.save },
                                  },
                                  [_vm._v("Save")]
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.quitDate === ""
                        ? _c("div", { staticClass: "v--15" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "vapetracker__copy type--content-styles type--content-margins type--content-links",
                              },
                              [
                                _c("h2", [_vm._v("Please come back later")]),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "The Vape Tracker will become available after completeing the 'My Time to Quit' Module."
                                  ),
                                ]),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("transition", { attrs: { name: "fade" } }, [
                _vm.state === "complete"
                  ? _c("div", { staticClass: "v--15" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vapetracker__copy type--content-styles type--content-margins type--content-links",
                        },
                        [
                          _c("h2", [_vm._v("Great work, thanks!")]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "Keep adding to so you can watch your quit goal progress as you move through the program."
                            ),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }