var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "embed r v--25" }, [
    _vm.instance.title || _vm.instance.content
      ? _c("div", { staticClass: "embed__top v--15" }, [
          _vm.instance.title
            ? _c("h2", {
                staticClass: "embed__title type--h2",
                domProps: { innerHTML: _vm._s(_vm.instance.title) },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.instance.content
            ? _c("div", {
                staticClass:
                  "embed__content type--content-links type--content-margins type--content-styles",
                domProps: { innerHTML: _vm._s(_vm.instance.content) },
              })
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { ref: "videoParent", staticClass: "embed__video embed-container" },
      [
        _c("iframe", {
          ref: "video",
          attrs: {
            src: _vm.src,
            allow:
              "autoplay; fullscreen; encrypted-media; autopause; playsinline;",
            allowfullscreen: "",
            width: "500",
            height: "281",
            frameborder: "0",
          },
        }),
      ]
    ),
    _vm._v(" "),
    _vm.allowNext === true && _vm.instance.required === true
      ? _c(
          "div",
          {
            staticClass:
              "embed__footer r r--justify-center r--halign-center v--30 type--align-center",
          },
          [
            _c("transition", { attrs: { name: "button" } }, [
              _vm.isValid && !_vm.instance.last
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn--solid",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("next")
                        },
                      },
                    },
                    [_vm._v("\n                Next >\n            ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isValid && _vm.instance.last
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn--solid",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    },
                    [_vm._v("\n                Home\n            ")]
                  )
                : _vm._e(),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }