<template>
  <span v-if="siloData.user.id !== 0" id="vapetracker" style="display: none;">
	<div class="vapetracker c c--content--pad r" :data-state="state" :data-processing="processing">
		<transition name="fade">
			<div v-if="state==='ready'">		
				<div v-if="quitDate !== ''" class="v--15">
					<div class="vapetracker__copy" v-html="siloData.messages.vape_calendar_copy"></div>
					<div class="vapetracker__quitedate type--vt--small type--align-center" v-if="siloData.user.modules.meta_vt_target_date">
						Your Quit Date: <span v-text="quitDate"></span>
					</div>
					<div class="vapetracker__calendar type--align-center">
						<v-calendar 
							:attributes='attributes()' 
							:timezone="siloData.user.tz" 
							:max-date='new Date()' 
							@dayclick='dayClicked'
							is-expanded></v-calendar>
					</div>
					<div class="vapetracker__count type--vt--small type--align-center">
						<h5>Total Vape-Free Days: {{days.length}}</h5>    
					</div>
					<div class="vapetracker__footer type--align-center">
						<button class="btn btn--solid" @click='save' :disabled="buttonDisabled">Save</button>
					</div>
				</div>
				<div v-if="quitDate === ''" class="v--15">
					<div class="vapetracker__copy type--content-styles type--content-margins type--content-links">
						<h2>Please come back later</h2>
						<p>The Vape Tracker will become available after completeing the 'My Time to Quit' Module.</p>
					</div>
				</div>
			</div>
		</transition>
		<transition name="fade">
			<div v-if="state==='complete'" class="v--15">
				<div class="vapetracker__copy type--content-styles type--content-margins type--content-links">
					<h2>Great work, thanks!</h2>
					<p>Keep adding to so you can watch your quit goal progress as you move through the program.</p>
				</div>
			</div>
		</transition>
	</div>
  </span>
</template>

<script>
import Vue from 'vue'
import moment from "moment";
const axios = require('axios');
import Calendar from 'v-calendar/lib/components/calendar.umd'

// Doesn't look like you need this
// Vue.component('calendar', Calendar)

// Working with data: https://docs.vcalendar.io/data.html

export default {
	components: {
		Calendar,
	},
	props: {
		appState: String,
		siloData: Object
	},
	data() {
		return {
			state: "ready", // ready, complete
			processing: false, // true or false
			selectedDay: null, // currently selected date
			backgroundColor: '#755ad8', // background of circles
			color: '#ffffff', // text color on circles
			buttonDisabled: true,
			modalAttrs: {
				button: false,
				message_is_element: true,
				message: '.vapetracker',
				additional_classes: 'notice-centered',
				onClose: this.close,
			}
			//days: this.siloData.user.modules.dates, // dates array
		}
	},
	computed: {
		quitDate() {
			let returned = "";

			// Sometimes this is an array, so I added a check to make sure we get the right date either way
			if (Array.isArray(this.siloData.user.modules.meta_vt_target_date)) {
				let date = this.siloData.user.modules.meta_vt_target_date[0];
				if (date === "" || date === undefined) {
					returned = "";
				} else {
					returned = this.formatQuitDate(date);
				}
			} else {
				let date = this.siloData.user.modules.meta_vt_target_date;
				if (date === "" || date === undefined) {
					returned = "";
				} else {
					returned = this.formatQuitDate(date);
				}
			}
			return returned;
		},
		days() {
			let days_unfiltered = this.siloData.user.modules.dates;

			// Filter out empty array strings
			let days_filtered = days_unfiltered.filter(function (el) {
				return el != "";
			});

			// Map days filter onto dates
			let dates = days_filtered.map(day => ({
				id: day,
				date: new Date(day),
			}));

			return dates;
		},
	},
	mounted() {
		window.vapetracker = this;
	},
	methods: {
		open() {
			modal.set(this.modalAttrs);
			window.events.postPageView({type:'vapetracker'});
		},
		// STEP 3
		attributes() {
			// Take the value of data.dates
			// Create a new array of all those dates
			// Then make that array the v-model of the v-calendar
			return this.days.map((date, index) => ({
				key: `todo.${index}`,
				highlight: {
					style: {                     
						background: this.backgroundColor
					},
					contentStyle: {              
						color: this.color
					}
				},
				// We need to add 12 hours here so that the day we click ends up being correct
				dates: this.addHours(12, date.id),
			}));
		},
		formatQuitDate(date) {
			return moment.utc(date).format('M/D/YYYY');
		},
		// This adds X hours to whatever date you push up
		addHours(numOfHours, date) {
			let date_obj = new Date(date);
			date_obj.setTime(date_obj.getTime() + numOfHours * 60 * 60 * 1000);
			return date_obj;
		},
		dayClicked(day) {

			// Show button
			this.buttonDisabled = false;

			// const idx = this.days.findIndex(d => d.id === day.id);
			// This had to be changed to compare the clicked ID against the days data array which has no ID
			const idx = this.days.findIndex(d => d.id === day.id);
			
			// If removing a date
			if (idx >= 0) {
				this.days.splice(idx, 1);
				// Post date change event
				this.postVTChange(day.id, false);
			// If adding a date	
			} else {
				this.days.push({
					id: day.id,
					date: new Date(day.id),
				});
				// Post date change event
				this.postVTChange(day.id, true);
			}

			this.$forceUpdate();
		},
		save() {

			// The days that are being pushed up need to be pushed up as just a list of strings
			// So we will need to filter these better

			let dates = this.formatForPost(this.days);

			axios.post(this.siloData.urls.postvapecalendar, dates)  
				.then((response) => {
					// Submit date changes
					this.submitVTChanges(dates);
					this.complete();
				})
				.catch((error) => {
					console.log(error);
				});
			},
	    complete() {
			this.state = "complete";
			// Hide button
			this.buttonDisabled = true;
		},
		close() {
			// console.log('reseting vape tracker');
			// This gets called by the onClose callback within modal.set in the ToolsMenu button
			this.state = "ready";
		},
		// format our data so it posts to server correctly
		formatForPost(dates_array) {
			let dates = dates_array.map(day => (
				day.id
			));
			return dates;
		},
		// Post date change event
		postVTChange(date, setting) {
            let data = {
                type: 'vapetracker',
                date: date,
                setting: setting,
            };
            window.events.postAction(data); 
        },
		// Submitting VT data
		submitVTChanges(dates) {
            let data = {
                type: 'vapetracker',
                dates: dates,
            };
            window.events.postSubmitData(data); 
        },
	},
}
</script>

<style lang="scss">
@import "@s/_vars.scss";
@import "@s/_mixins.scss";
.vapetracker {
	&[data-state="ready"] {

	}
	&[data-state="processing"] {

	}
	&[data-state="complete"] {

	}
}

.vapetracker__copy {
	h2 {
		color: var(--color-purple);
	}
}

.vapetracker__quitedate {
	margin-top: rem(30px);
}

.vapetracker__count {

}

.type--vt--small {
	font-size: rem(15px);
	line-height: lh(15px, 20px);
	color: var(--color-purple);
}

// Disable clicks on disabled days
.vc-day-content.vc-focusable.is-disabled {
	pointer-events: none;
}

.vapetracker__footer {
	button {
		margin-inline: auto;
	}
}

.fade-enter-active,
.fade-leave-active {
	> * {
		transition: all 0.25s linear;
	}
}

.fade-enter {
	position: absolute;
	> * {
		opacity: 0;
	}
}

.fade-enter-to {
	position: relative;
	> * {
		opacity: 1;
	}
}

.fade-leave-to {
	> * {
		opacity: 0;
	}
}
</style>