var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "affirmation",
      attrs: { "data-processing": _vm.processing },
    },
    [
      _vm.type === "ifthen"
        ? _c(
            "div",
            {
              staticClass:
                "affirmation__response affirmation__response--ifthen v--25",
            },
            [
              _vm.selectedResponse.then
                ? _c("div", {
                    staticClass:
                      "affirmation__response__then type--content-styles type--content-margins type--content-links",
                    class: _vm.selectedResponse.is_correct,
                    domProps: { innerHTML: _vm._s(_vm.selectedResponse.then) },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedResponse.image
                ? _c(
                    "div",
                    {
                      staticClass:
                        "affirmation__response__image r r--justify-center r--halign-center",
                    },
                    [
                      _c("PictureDynamic", {
                        attrs: {
                          id: _vm.selectedResponse.image,
                          size: _vm.imageSize,
                          alt: "Affirmation Image",
                          siloData: _vm.siloData,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.type === "empty"
        ? _c(
            "div",
            {
              staticClass:
                "affirmation__response affirmation__response--empty v--25",
            },
            [
              _vm.isEmpty
                ? _c(
                    "div",
                    {
                      staticClass:
                        "affirmation__response__container affirmation__response__container--empty",
                    },
                    [
                      _c("div", {
                        staticClass:
                          "affirmation__response__then type--content-styles type--content-margins type--content-links",
                        domProps: { innerHTML: _vm._s(_vm.instance.if) },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "affirmation__response__image r r--justify-center r--halign-center",
                        },
                        [
                          _c("Picture", {
                            attrs: {
                              id: _vm.instance.image_if,
                              size: _vm.imageSize,
                              alt: "Affirmation Image",
                              siloData: _vm.siloData,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.isEmpty
                ? _c(
                    "div",
                    {
                      staticClass:
                        "affirmation__response__container affirmation__response__container--not-empty",
                    },
                    [
                      _c("div", {
                        staticClass:
                          "affirmation__response__then type--content-styles type--content-margins type--content-links",
                        domProps: { innerHTML: _vm._s(_vm.instance.else) },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "affirmation__response__image r r--justify-center r--halign-center",
                        },
                        [
                          _c("Picture", {
                            attrs: {
                              id: _vm.instance.image_else,
                              size: _vm.imageSize,
                              alt: "Affirmation Image",
                              siloData: _vm.siloData,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.type === "ifelse"
        ? _c(
            "div",
            {
              staticClass:
                "affirmation__response affirmation__response--ifelse v--25",
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isAnswerThis,
                      expression: "isAnswerThis",
                    },
                  ],
                  staticClass:
                    "affirmation__response__container affirmation__response__container--is",
                },
                [
                  _vm.instance.if_correct === "yes"
                    ? _c("Kudo", {
                        attrs: {
                          instance: _vm.instance,
                          active: _vm.isAnswerThis,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", {
                    staticClass:
                      "affirmation__response__then type--content-styles type--content-margins type--content-links",
                    domProps: { innerHTML: _vm._s(_vm.instance.if) },
                  }),
                  _vm._v(" "),
                  _vm.instance.image_if
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "affirmation__response__image r r--justify-center r--halign-center",
                        },
                        [
                          _c("Picture", {
                            attrs: {
                              id: _vm.instance.image_if,
                              size: _vm.imageSize,
                              alt: "Affirmation Image",
                              siloData: _vm.siloData,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isAnswerThis,
                      expression: "!isAnswerThis",
                    },
                  ],
                  staticClass:
                    "affirmation__response__container affirmation__response__container--is-not",
                },
                [
                  _vm.instance.else_correct === "yes"
                    ? _c("Kudo", {
                        attrs: {
                          instance: _vm.instance,
                          active: !_vm.isAnswerThis,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", {
                    staticClass:
                      "affirmation__response__then type--content-styles type--content-margins type--content-links",
                    domProps: { innerHTML: _vm._s(_vm.instance.else) },
                  }),
                  _vm._v(" "),
                  _vm.instance.image_else
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "affirmation__response__image r r--justify-center r--halign-center",
                        },
                        [
                          _c("Picture", {
                            attrs: {
                              id: _vm.instance.image_else,
                              size: _vm.imageSize,
                              alt: "Affirmation Image",
                              siloData: _vm.siloData,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }