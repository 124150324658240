var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "parent",
      staticClass: "ms ms--regular ms--page",
      class: _vm.index === _vm.activeSlide ? "active" : "inactive",
      attrs: { "data-id": _vm.index },
    },
    [
      _c("div", { staticClass: "ms__wrap c c--content--pad r pad--h v--40" }, [
        _c("div", { staticClass: "ms__top v--15" }, [
          _vm.index !== 0
            ? _c(
                "button",
                {
                  staticClass: "ms__back link",
                  on: {
                    click: function ($event) {
                      return _vm.home()
                    },
                  },
                },
                [_vm._v("< Back")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { ref: "content", staticClass: "ms__content v--20" },
            _vm._l(_vm.sd.panels_meta.widgets, function (instance) {
              return _c(
                "div",
                { key: instance.panels_info.id },
                [
                  instance.panels_info.class === "Text_Panel"
                    ? _c("PanelText", {
                        attrs: { instance: instance, active: _vm.active },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  instance.panels_info.class === "Embed_Panel"
                    ? _c("PanelEmbed", {
                        attrs: {
                          appState: _vm.appState,
                          instance: instance,
                          active: _vm.active,
                          pauseVideo: _vm.pauseVideo,
                          allowNext: false,
                        },
                        on: { playingVideo: _vm.playingVideo },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  instance.panels_info.class === "Show_Hide_Panel"
                    ? _c("PanelShowHide", {
                        attrs: { instance: instance, siloData: _vm.siloData },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }