<template>
  <div id="appManager" v-if="readyToUse" :data-processing="processing">
    <Events :siloData="data"/>
    <EntryHeader :appState="appState" :siloData="data"/>
    <HomeScreen :appState="appState" :siloData="data"/>
    <ToolsMenu :appState="appState" :siloData="data" @updateState="updateState"/>
    <VapeTracker :appState="appState" :siloData="data"/>
    <Card :appState="appState" :siloData="data" activator="help" v-bind:hasChildren="true" @updateState="updateState" @processing="setProcessing" @refreshData="refreshData"/>
    <Card :appState="appState" :siloData="data" activator="tasks" v-bind:hasChildren="false" @updateState="updateState" @processing="setProcessing" @refreshData="refreshData"/>
    <Card :appState="appState" :siloData="data" activator="plan" v-bind:hasChildren="false" @updateState="updateState" @processing="setProcessing" @refreshData="refreshData"/>
    <ModuleLoader :appState="appState" :siloData="data" @updateState="updateState" @processing="setProcessing" @refreshData="refreshData" @moduleComplete="moduleComplete"/>
    <VideoPlayer :appState="appState" :siloData="data"/>
    <CompletionModal :appState="appState" :siloData="siloData" :active="showModuleActive" @moduleCompleteReset="moduleCompleteReset"/>
    <!--
    <WelcomeModal :appState="appState" :siloData="siloData" :active="showWelcomeActive" @moduleWelcomeReset="moduleWelcomeReset"/>
    -->
    </div>
</template>

<script>
import Events from '@c/Events.vue'
import EntryHeader from '@c/EntryHeader.vue'
import HomeScreen from '@c/HomeScreen.vue'
import ToolsMenu from '@c/ToolsMenu.vue'
import VapeTracker from '@c/VapeTracker.vue'
import ModuleLoader from '@c/ModuleLoader.vue'
import Card from '@c/Card.vue'
import VideoPlayer from '@c/VideoPlayer.vue'
import CompletionModal from "@c/CompletionModal.vue";
// import WelcomeModal from '@c/WelcomeModal.vue'
const axios = require('axios');

export default {
    components: {
        Events,
        EntryHeader,
        HomeScreen,
        ToolsMenu,
        VapeTracker,
        ModuleLoader,
        Card,
        VideoPlayer,
        CompletionModal,
        //WelcomeModal,
    },
    data() {
        return {
            appState: 'home',
            processing: false,
            data: this.siloData,
            showModuleActive: false,
            //showWelcomeActive: false,
        }
    },
    computed: {
        readyToUse() {
            let returned = false;

            // Make sure the user has completed the login flow
            if (this.siloData.user.stats.has_completed_login_flow === 'true' && this.siloData.user.stats.has_signed_tou === 'true') {
                returned = true;
            }

            // Make sure we aren't on any of the following pages
            if (this.siloData.template === 'page-login-flow-page.php' || this.siloData.template === 'page-login.php') {
                returned = false;
            }

            // If we're on the home page
            if (this.siloData.template === '') {
                // And the login flow isn't complete
                if (this.siloData.user.stats.has_completed_login_flow !== 'true' || this.siloData.user.stats.has_signed_tou !== 'true') {
                    // Redirect to the login flow
                    this.redirectToStart();
                }
            }

            return returned;
        }
    },
    props: {
        siloData: Object
    },
    watch: {
        siloData() {
            this.refreshData();
        }
    },
    methods: { 
        // Redirect to the login flow
        redirectToStart() {
            window.location.href = this.siloData.urls.start;
        },
        updateState(newState) {
            console.log('updating state', newState);
            this.appState = newState;
        },
        setProcessing(state) {
            console.log('setting processing');
            this.processing = state;
        },
        async refreshData(onComplete = null) {
            
            this.setProcessing(true);

            const response = await axios(this.siloData.urls.getsilodata + '&id=' + this.siloData.user.id);
            
            if (response.err) { 
                console.log('something went wrong', response.err);
                this.setProcessing(false);
            } else { 
                console.log('fetched response', response.data);
                // This is a prop, so it needs to be passed up I think
                this.data = response.data;
                this.setProcessing(false);
                // Fire our callback if it exists
                if (onComplete !== null) {
                    this.$nextTick(() => {
                        console.log('app manager firing onComplete callback');
                        onComplete;
                    });
                }
            }
        },
        // Fires when a module is completed
        moduleComplete() {
            this.showModuleActive = true;
        },
        moduleCompleteReset() {
            this.showModuleActive = false;
        },
        /*
        moduleWelcomeReset() {
            this.showWelcomeActive = false;
        },
        showWelcomeModal() {
            this.showWelcomeActive = true;
        },
        */
    },
}
</script>