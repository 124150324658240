<template>
    <div class="input input--text v--10">
        <!--{{field}}-->

        <label
            v-if="field.field_label"
            :class="hideLabel" 
            v-html="field.field_label">
        </label>

        <input
            type="text"
            :value="siloData.user.modules[field.push_key]"
            :data-push-key="field.push_key"
            :placeholder="field.field_placeholder"
            :maxlength="field.max_chars"
            @keyup="$emit('validate')"
            data-watch-event="keyup"
            data-pristine-not-blank
            required
            >
    </div>
</template>

<script>
    export default {
        props: {
            field: Object,
            siloData: Object,
        },
        computed: {
            hideLabel() {
                return this.field.hide_label === true ? 'screen-reader-only' : '';
            },
        },
        methods: {

        }
    }
</script>

<style lang="scss" scoped>

</style>