var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.readyToUse
    ? _c(
        "div",
        { attrs: { id: "appManager", "data-processing": _vm.processing } },
        [
          _c("Events", { attrs: { siloData: _vm.data } }),
          _vm._v(" "),
          _c("EntryHeader", {
            attrs: { appState: _vm.appState, siloData: _vm.data },
          }),
          _vm._v(" "),
          _c("HomeScreen", {
            attrs: { appState: _vm.appState, siloData: _vm.data },
          }),
          _vm._v(" "),
          _c("ToolsMenu", {
            attrs: { appState: _vm.appState, siloData: _vm.data },
            on: { updateState: _vm.updateState },
          }),
          _vm._v(" "),
          _c("VapeTracker", {
            attrs: { appState: _vm.appState, siloData: _vm.data },
          }),
          _vm._v(" "),
          _c("Card", {
            attrs: {
              appState: _vm.appState,
              siloData: _vm.data,
              activator: "help",
              hasChildren: true,
            },
            on: {
              updateState: _vm.updateState,
              processing: _vm.setProcessing,
              refreshData: _vm.refreshData,
            },
          }),
          _vm._v(" "),
          _c("Card", {
            attrs: {
              appState: _vm.appState,
              siloData: _vm.data,
              activator: "tasks",
              hasChildren: false,
            },
            on: {
              updateState: _vm.updateState,
              processing: _vm.setProcessing,
              refreshData: _vm.refreshData,
            },
          }),
          _vm._v(" "),
          _c("Card", {
            attrs: {
              appState: _vm.appState,
              siloData: _vm.data,
              activator: "plan",
              hasChildren: false,
            },
            on: {
              updateState: _vm.updateState,
              processing: _vm.setProcessing,
              refreshData: _vm.refreshData,
            },
          }),
          _vm._v(" "),
          _c("ModuleLoader", {
            attrs: { appState: _vm.appState, siloData: _vm.data },
            on: {
              updateState: _vm.updateState,
              processing: _vm.setProcessing,
              refreshData: _vm.refreshData,
              moduleComplete: _vm.moduleComplete,
            },
          }),
          _vm._v(" "),
          _c("VideoPlayer", {
            attrs: { appState: _vm.appState, siloData: _vm.data },
          }),
          _vm._v(" "),
          _c("CompletionModal", {
            attrs: {
              appState: _vm.appState,
              siloData: _vm.siloData,
              active: _vm.showModuleActive,
            },
            on: { moduleCompleteReset: _vm.moduleCompleteReset },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }