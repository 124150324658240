<template>
    <div>
        <!--
            TODO: Write method which 'activates' a slide when the slide is visible
            - This would probably happen start within the ModuleLoader component and then pass to its children
            - For this component in particular, when it get's that event to active, just have it click the next slide once
            - Maybe pass down the current slide prop and have everyone watch it for changes, then if it's current slide is the same as
            - The one you are on, it activates?
        -->

    </div>
</template>

<script>
    export default {
        data() {
            return {
            }
        },
        props: {
            siloData: Object,
            instance: Object,
            active: Boolean,
        }, 
        mounted() {
            this.activeCheck();
        },
        watch: {
            active() {
                this.activeCheck();
            }
        },
        methods: {
            activeCheck() {
                if (this.active === true) {
                    // get direction from this element
                    let direction = document.getElementById('moduleloader').dataset.slideDirection;
                    // if the direction is forward, go forward one
                    if (direction === 'show-next') {
                        this.$emit('next');
                    // if the direction is backward, go backward one
                    } else {
                        this.$emit('prev');
                    }
                }
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>