<template>
  <div class="ms ms--regular ms--page" :class="index === activeSlide ? 'active' : 'inactive'" ref="parent" :data-id="index">
        <div class="ms__wrap c c--content--pad r pad--h v--40">
            <div class="ms__top v--15">
                <!-- first page doesn't have a back button -->
                <button
                    v-if="index !== 0"
                    @click="home()"
                    class="ms__back link">< Back</button>
                    <!-- {{sd}} -->
                <div ref="content" class="ms__content v--20">
                    <div v-for="instance in sd.panels_meta.widgets" :key="instance.panels_info.id">
                        <PanelText v-if="instance.panels_info.class === 'Text_Panel'" :instance="instance" :active="active"/>
                        <PanelEmbed v-if="instance.panels_info.class === 'Embed_Panel'" :appState="appState" :instance="instance" :active="active" :pauseVideo="pauseVideo" v-bind:allowNext="false" @playingVideo="playingVideo"/>
                        <PanelShowHide v-if="instance.panels_info.class === 'Show_Hide_Panel'" :instance="instance" :siloData="siloData"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PanelText from "@c/panels/PanelText.vue";
import PanelEmbed from "@c/panels/PanelEmbed.vue";
import PanelShowHide from "@c/panels/PanelShowHide.vue";
export default {
        components: {
        PanelText,
        PanelEmbed,
        PanelShowHide,
    },
	props: {
		sd: Object, // aka 'slide data'
        index: Number, // the index of this slide
        slides: Array, // all slide data
        siloData: Object,
        appState: String,
        // activeSlide: Number,
        activeSlide: String,
        active: Boolean,
	},
    data() {
        return {
            pauseVideo: true,
        } 
    },
    mounted() {
        this.updateLinks();
    },
    computed: {
        // Adds 1 to index, since index is always based on 0
        actualIndex() {
            return this.index + 1;
        },
        isLast() {
            return this.actualIndex === this.slides.length ? true : false;
        },
    },
    methods: {
        // Go through the links and update them so they navigate within the content of the app
        updateLinks() {
            let anchors = this.$refs.content.getElementsByTagName('a');
            anchors.forEach((anchor) => {
                // Save the url
                let saved_href = anchor.href;
                // Change the current url to pound
                anchor.href="#";
                // Break up url into pieces
                let url_pieces = saved_href.split("/");
                // Get the final url piece which should be our slug
                let slug = url_pieces[url_pieces.length - 2];
                
                anchor.onclick = function() { window.pageLoader.set(slug); return false; };
            });
        },
        home() {
            // emit prev to parent
            this.$emit('home');
            this.pauseVideo = true;
        },
        // Emitted when playing a video
        playingVideo() {
            this.pauseVideo = false;
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@s/_mixins.scss";
@import "@s/_vars.scss";
.ms--page {
    height: 100vh;
    padding-top: 0;
    background-color: var(--color-white);
    padding-bottom: rem(90px);
    // The first page has no breadcrumb so it needs some padding difference
    &[data-id="0"] {
        .ms__content {
            padding-top: rem(20px);
        }
    }
}
.ms__wrap {
   // padding-top: rem(40px);
}
</style>