var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "parent", staticClass: "form-panel v--30" }, [
    _vm.instance.title || _vm.instance.content
      ? _c("div", { staticClass: "form-panel__top v--15" }, [
          _vm.instance.title
            ? _c("h2", {
                staticClass: "form-panel__title type--h2",
                domProps: { innerHTML: _vm._s(_vm.instance.title) },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.instance.content
            ? _c("div", {
                staticClass:
                  "form-panel__content type--content-links type--content-margins type--content-styles",
                domProps: { innerHTML: _vm._s(_vm.instance.content) },
              })
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.instance.image !== 0
      ? _c("div", { staticClass: "text__content__image" })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "form",
      {
        ref: "form",
        staticClass: "form form--v required v--50",
        attrs: {
          action: "",
          method: "post",
          "data-type": _vm.type,
          "data-on-submit-url": _vm.on_submit_url,
        },
      },
      [
        _c(
          "div",
          { staticClass: "form__fields r v--30" },
          _vm._l(_vm.instance.form.widgets, function (field, index) {
            return _c(
              "div",
              { key: index, staticClass: "input input--buttons v--25" },
              [
                field.panels_info.class === "Form_Buttons"
                  ? _c("Buttons", {
                      attrs: { field: field, siloData: _vm.siloData },
                      on: { validate: _vm.validate },
                    })
                  : _vm._e(),
                _vm._v(" "),
                field.panels_info.class === "Form_Checkboxes"
                  ? _c("CheckBoxes", {
                      attrs: { field: field, siloData: _vm.siloData },
                      on: { validate: _vm.validate },
                    })
                  : _vm._e(),
                _vm._v(" "),
                field.panels_info.class === "Form_Date"
                  ? _c("Date", {
                      attrs: { field: field, siloData: _vm.siloData },
                      on: { validate: _vm.validate },
                    })
                  : _vm._e(),
                _vm._v(" "),
                field.panels_info.class === "Field_Text"
                  ? _c("TextField", {
                      attrs: { field: field, siloData: _vm.siloData },
                      on: { validate: _vm.validate },
                    })
                  : _vm._e(),
                _vm._v(" "),
                field.panels_info.class === "Field_Textarea"
                  ? _c("TextAreaField", {
                      attrs: { field: field, siloData: _vm.siloData },
                      on: { validate: _vm.validate },
                    })
                  : _vm._e(),
                _vm._v(" "),
                field.panels_info.class === "Form_Likert"
                  ? _c("Likert", {
                      attrs: { field: field, siloData: _vm.siloData },
                      on: { validate: _vm.validate },
                    })
                  : _vm._e(),
              ],
              1
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm._l(_vm.instance.hidden_fields, function (field, index) {
          return _c("div", { key: index }, [
            _c("input", {
              attrs: {
                type: "hidden",
                name: field.key,
                "data-push-key": field.key,
                "data-meta-key": field.key,
                required: "",
              },
              domProps: { value: field.value },
            }),
          ])
        }),
        _vm._v(" "),
        _vm.instance.post_form_content
          ? _c("div", {
              staticClass:
                "form__pfc r type--content-styles type--content-margins",
              domProps: { innerHTML: _vm._s(_vm.instance.post_form_content) },
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "form__footer r r--justify-center r--halign-center v--30 type--align-center",
          },
          [
            _c("transition", { attrs: { name: "button" } }, [
              _vm.is_valid
                ? _c("button", {
                    staticClass: "form__submit btn btn--solid",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.instance.button_text
                          ? _vm.instance.button_text
                          : "Next >"
                      ),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.submit.apply(null, arguments)
                      },
                    },
                  })
                : _vm._e(),
            ]),
          ],
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }