import { render, staticRenderFns } from "./ToolsMenu.vue?vue&type=template&id=31dab0d4&"
import script from "./ToolsMenu.vue?vue&type=script&lang=js&"
export * from "./ToolsMenu.vue?vue&type=script&lang=js&"
import style0 from "./ToolsMenu.vue?vue&type=style&index=0&id=31dab0d4&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/nick/Local/kicknic/app/public/wp-content/themes/kicknic/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('31dab0d4')) {
      api.createRecord('31dab0d4', component.options)
    } else {
      api.reload('31dab0d4', component.options)
    }
    module.hot.accept("./ToolsMenu.vue?vue&type=template&id=31dab0d4&", function () {
      api.rerender('31dab0d4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/scripts/components/ToolsMenu.vue"
export default component.exports