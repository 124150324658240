<template>
    <div class="showhide" :data-active="active">
        <button @click="toggle">
            <h2 class="showhide__title type--h2">
                <span class="showhide__title__indicator" v-text="active ? '-' : '+'"></span>
                <span class="showhide__title__text" v-text="shTitle"></span>
            </h2>
        </button>
        <div ref="container" class="showhide__content">
            <div ref="inner" class="showhide__content__inner type--content-links type--content-margins type--content-styles" v-html="shContent"></div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                active: false,
            }
        },
        methods: {
            toggle() {
                if (this.active) {
                    this.hide();
                } else {
                    this.show();
                }
            },
            show() {
                this.active = true;
                // Get the height of the content of the container
                let height = this.$refs.inner.clientHeight + 'px';
                setTimeout(() => {
                    // Set the height of the container to the height of the inner content
                    this.$refs.container.style.height = height;
                }, 0);
            },
            hide() {
                // Set the height of the container back to 0
                this.$refs.container.style.height = '0px';
                this.active = false;
            },
        },
        props: {
            shTitle: String,
            shContent: String,
        },
    }
</script>

<style lang="scss">
@import "@s/_mixins.scss";
@import "@s/_vars.scss";
.showhide__title__indicator {
    position: absolute;
    left: 0;
}
.showhide__title__text {
    padding-left: rem(20px);
}
.showhide__content {
    padding-left: rem(15px);
    transition: height 0.35s ease-in-out;
    overflow: hidden;
    height: 0;

}
.showhide__content__inner {
    padding-top: rem(15px);
    padding-bottom: rem(15px);
}
</style>