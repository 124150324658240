var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "showhide", attrs: { "data-active": _vm.active } },
    [
      _c("button", { on: { click: _vm.toggle } }, [
        _c("h2", { staticClass: "showhide__title type--h2" }, [
          _c("span", {
            staticClass: "showhide__title__indicator",
            domProps: { textContent: _vm._s(_vm.active ? "-" : "+") },
          }),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "showhide__title__text" },
            [
              _vm._t("title", function () {
                return [_vm._v("Title")]
              }),
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { ref: "container", staticClass: "showhide__content" }, [
        _c(
          "div",
          {
            ref: "inner",
            staticClass:
              "showhide__content__inner type--content-links type--content-margins type--content-styles",
          },
          [
            _vm._t("content", function () {
              return [_vm._v("Content")]
            }),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }