<template>
    <div class="variable" :class="`variable--${instance.type} variant--${instance.variant}`" :data-field="instance.field">
        <!--{{instance}}-->
        <div class="variable__response type--content-styles type--content-margins type--content-links v--20">
            <div v-if="instance.header" class="variable__response__header">
                <div :class="instance.header_type" v-text="instance.header"></div>
            </div>
            <div class="variable__response__text">
                <span v-if="instance.type === 'default'" v-html="formatAs(answer)"></span>
                <ul v-if="instance.type === 'list'" :class="`list--${instance.list_style}`">
                    <li v-for="item in answer" :key="item.id">
                        {{ formatAs(item) }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    export default {
        components: {
        },
        data() {
            return {
            }
        },
        computed: {
            answer() {
                let answer = this.siloData.user.modules[this.instance.field];
                let answer_array = [];

                // If this is an array, pass the answer to the answer array
                if (Array.isArray(answer)) {
                    answer_array = answer;
                    return answer_array;
                // Otherwise, set the first index of the answer array to the answer
                } else {
                    answer_array[0] = answer;
                    return answer_array;
                }
            },
        },
        props: {
            siloData: Object,
            instance: Object,
        },
        watch: {

        },
        methods: {
            formatAs(string) {
                let new_string = "";
                let returned = "";

                // If we're passing an array, just grab the first item
                if (Array.isArray(string)) {
                    new_string = string[0];
                } else {
                    new_string = string;
                }
                
                if (this.instance.format === "date") {
                    returned = moment.utc(new_string).format('M/D/YYYY');
                } else {
                    returned = new_string;
                }

                return returned;
            }
        }
    }
</script>

<style lang="scss">
@import "@s/_mixins.scss";
@import "@s/_vars.scss";
.variant--large {
    .variable__response__text * {
        font-size: rem(18px);
        line-height: lh(18px, 24px);
        letter-spacing: 0.22px;
    }
}
.list--double {
    margin-left: rem(40px) !important;
    list-style-type:circle !important;
}
</style>