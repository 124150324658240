<template>
  <div class="login v--40" v-bind:class="form_class" :data-state="form_state">

    <div class="eh eh--login bg-color--dark">
        <div class="eh__content c c--content--pad pad--h v--5">
            <div class="eh__meta type--small">Welcome to</div>
            <h1 class="eh__title type--h1 change">
                <Icon name="kicknic-logo-new"/>
            </h1>
        </div>
    </div>

    <div class="login__row">
        <div class="login__form c c--narrow bg-color--cream">
            <div class="login__welcome pad--h">
                <h2 class="notice__message type--h2" v-html="current_notice"></h2>
            </div>
            <div class="login__form__row r">
                <div class="notice type--dark-background">
                    <div class="notice__container">
                        <div class="notice__message type--align-center pad--h"></div>
                        <div class="notice__loading">
                            <!-- make this a component -->
                            <div class="spinner-box">
                                <div class="pulse-container">
                                    <div class="pulse-bubble pulse-bubble-1"></div>
                                    <div class="pulse-bubble pulse-bubble-2"></div>
                                    <div class="pulse-bubble pulse-bubble-3"></div>
                                </div>
                            </div>
                            <!-- -->
                        </div>
                    </div>
                </div>
                <form name="loginform" id="loginform" class="form pad--h" :action="action" method="post">
                    <div class="login__form__body">
                        <ul class="form__body__fields v--50">
                            <li class="field validate--empty r">
                                <label for="user_login" class="type--label">User Name</label>
                                <input v-model="username" name="log" id="user_login" type="text" autocomplete="username"/>
                                <span class="message"></span>
                            </li>
                            <li class="field validate--empty r">
                                <label for="user_pass" class="type--label">Password</label>
                                <span>
                                    <input v-model="password" name="pwd" id="user_pass" :type="password_input_type" autocomplete="current-password" />
                                    <a href="#" class="svg show-password" :data-state="password_showing_state" aria-label="Show Password" @click="toggle_show_password">
                                        <!-- eye svg, make this a component? -->
                                            <svg width="15" height="15" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="#000" stroke-width="1.4" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M11.55 11.55a6.866 6.866 0 0 1-4.05 1.405C2.727 12.955 0 7.5 0 7.5a12.58 12.58 0 0 1 3.45-4.05m2.618-1.24c.47-.11.95-.166 1.432-.165C12.273 2.045 15 7.5 15 7.5a12.614 12.614 0 0 1-1.473 2.175m-4.582-.73a2.045 2.045 0 1 1-2.89-2.89"/><path class="cross" d="m0 0 15 15"/></g></svg>
                                        <!-- -->
                                    </a>
                                </span>
                                <span class="message"></span>
                            </li>
                            <li v-if="silo_data.messages.pw_assistance" class="field login__form__note type--align-right">
                                <span><a href="#" class="type--comment type--color--purple" @click="open_modal_help">Need Help Logging-In?</a></span>
                            </li>
                        </ul>
                    </div>
                    <div class="login__form__footer pad--h">
                        <div class="login__form__footer__wrapper r r--halign-center r--valign-center">
                            <input class="btn btn--solid btn--purple" name="wp-submit" id="wp-submit" type="submit" aria-label="Log In" value="Log In" @click.prevent="login" />
                            <div v-if="current_explaination" class="explaination">
                                <div v-html="current_explaination"></div>
                            </div>
                            <input type="hidden" name="redirect_to" :value="this.silo_data.urls.home + '?logged-in=true'">
                            <input type="hidden" name="testcookie" value="1" />
                            <input type="hidden" name="login" value="true" />
                            <input v-if="nonce" type="hidden" id="security" name="security" :value="nonce" />
                            <input type="hidden" name="_wp_http_referer" value="/login" />
                        </div>
                    </div>
                </form>
            </div>
            <div class="login__version r r--justify-center type--align-center pad--h"><span>{{version}}</span></div>
        </div>
    </div>

    <!--
    <div v-if="silo_data.messages.login_about" class="field login__about type--align-center v--clear">
        <span><a href="#" class="type--comment type--color--blue" @click="open_modal_about">About this App</a></span>
    </div>
    -->

</div>
</template>

<script>
import Icon from "@c/Icon.vue";
const axios = require('axios');
export default {
    components: {
        Icon
    },
    data() {
        return {
            username: '',
            password: '',
            current_notice: "Please log in to continue.",
            current_explaination: "",
            notice: {
                default: "Please log in to continue.",
                success: "Login successful!",
                error: "User Name or Password not recognized.",
                loading: "Logging in...",
            },
            silo_data: JSON.parse(document.querySelector('.silo-data').dataset.all),
            password_input_type: 'password',
            password_showing_state: 'inactive',
            form_class: '',
            form_state: '',
        }
    },
    props: {
        nonce: String,
        referer: String,
        action: String,
        version: String,
    },
    mounted() {
        this.show_form();
    },
    methods: {
        show_form() {
            setTimeout(() => {
                document.documentElement.classList.remove('loading');
                document.documentElement.classList.add('loaded');
            }, 300);
        },
        // Check the current login status
        check_login_status() {
            // Maybe do this later
        },
        open_modal_help() {
            modal.set(
                {
                    button:false, 
                    message:this.silo_data.messages.pw_assistance, 
                    additional_classes:'notice-centered'
                }
            );
        },
        open_modal_about() {
            modal.set(
                {
                    button:false, 
                    message:this.silo_data.messages.login_about, 
                    additional_classes:'notice-centered'
                }
            );
        },
        toggle_show_password() {
            if (this.password_input_type === "password") {
                this.password_input_type = "text";
                this.password_showing_state = "active";
            } else {
                this.password_input_type = "password"
                this.password_showing_state = "inactive";
            }
        },
        fields_are_valid() {
            return this.validate_empty() ? true : false;
        },
        validate_empty() {

            let required_fields = document.querySelectorAll('.field.validate--empty');
            let required_fields_filled_in = 0;

            required_fields.forEach((field) => {

                let message = field.querySelector('.message');
                let input = field.querySelector('input');
                let label = field.querySelector('label');

                if (field.querySelector('input').value.length > 0) {
                    required_fields_filled_in += 1;

                } else {
                    field.classList.add('required--attention');
                    message.innerText = 'Please enter a valid ' + label.innerText;

                    input.addEventListener('keyup', () => {
                        field.classList.remove('required--attention');
                        message.innerText = "";
                    }, {
                        once: true
                    })
                }
            });

            // Return true if all required fields are filled in
            return required_fields_filled_in === required_fields.length ? true : false;
        },
        login() {
            if (!this.fields_are_valid()) { return; }
            this.form_class = "disabled";
            this.form_state = "";
            this.current_notice = this.notice.loading;

            let data = {
                action: 'ajax_login',
                username: this.username,
                password: this.password,
                security: this.nonce,
            }

            setTimeout(() => {
            axios.post(this.silo_data.urls.login, data)  
                .then((response) => {
                    console.log("submission response", response);
                    console.log(response.data.loggedin);
                    if (response.data.loggedin === true) {
                        this.finish_form(response.data.redirect);
                    } else {
                        this.form_class = "";
                        this.form_state = "error";
                        this.current_notice = this.notice.default;
                        this.current_explaination = this.notice.error;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.form_class = "";
                    this.form_state = "error";
                    this.current_notice = this.notice.default;
                    this.current_explaination = this.notice.error;
                });
            }, 1500);
        },
        finish_form(redirect) {
            console.log('finishing form');
            this.current_notice = this.notice.success;
            setTimeout(()=> {
                this.current_notice = this.notice.success;
                this.advance_page(redirect);
            }, 1500);
        },
        advance_page(redirect) {
            console.log('advancing page');
            this.current_class = this.current_class + " completed";
            setTimeout(() => {
                // Might have to do this like window.location.href = redirect;
                uma.go(redirect + "?logged-in=true");
                console.log('redirected');
            }, 1500);
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@s/_mixins.scss";
@import "@s/_vars.scss";
.notice__message {
    color: var(--color-purple);
}
.login__version {
    color: var(--color-grey);
    text-align: right;
    font-size: rem(11px);
    margin-top: rem(60px);
}
</style>