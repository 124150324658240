var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "session type--align-center v--20" }, [
    _vm.instance.icon !== null && _vm.instance.icon !== ""
      ? _c(
          "div",
          { staticClass: "session__icon" },
          [_c("IconSession", { attrs: { name: _vm.instance.icon } })],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("h1", {
      staticClass: "session__title type--h1--session",
      domProps: { innerHTML: _vm._s(_vm.instance.title) },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }