<template>
    <div class="sh v--30">
        <!--{{instance}}-->
        <div v-if="instance.title || instance.content" class="sh__top v--15">
            <h2 v-if="instance.title" class="sh__title type--h2" v-html="instance.title"></h2>
            <div v-if="instance.content" class="sh__content type--content-links type--content-margins type--content-styles" v-html="instance.content"></div>
        </div>
        <div v-for="(section, index) in instance.sections" :key="index" class="sh__section r">
            <!-- {{section}} -->
            <ShowHide :shTitle="section.title" :shContent="section.content" />
        </div>
    </div>
</template>

<script>
import ShowHide from "@c/elements/ShowHide.vue";
    export default {
        components: {
            ShowHide,
        },
        props: {
            instance: Object,
            siloData: Object,
        },
    }
</script>

<style lang="scss" scoped>
@import "@s/_mixins.scss";
@import "@s/_vars.scss";

</style>