var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "img", attrs: { "data-loading": _vm.loading } },
    [_c("img", { attrs: { src: _vm.src, "data-src": _vm.src, alt: _vm.alt } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }