var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "conditional" }, [
    _vm.type === "ifelse"
      ? _c(
          "div",
          {
            staticClass:
              "conditional__response conditional__response--ifelse v--25",
          },
          [
            _vm.isAnswerThis
              ? _c(
                  "div",
                  {
                    staticClass:
                      "conditional__response__container conditional__response__container--if v--20",
                  },
                  [
                    _vm._l(
                      _vm.instance.if_panels.widgets,
                      function (inst, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "conditional__panel" },
                          [
                            inst.panels_info.class === "Kudo"
                              ? _c("Kudo", {
                                  attrs: {
                                    instance: inst,
                                    siloData: _vm.siloData,
                                    active: _vm.active,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            inst.panels_info.class === "Text_Panel"
                              ? _c("PanelText", {
                                  attrs: {
                                    instance: inst,
                                    siloData: _vm.siloData,
                                    active: _vm.active,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            inst.panels_info.class === "Form"
                              ? _c("PanelForm", {
                                  attrs: {
                                    instance: inst,
                                    siloData: _vm.siloData,
                                  },
                                  on: {
                                    next: _vm.next,
                                    processing: _vm.setProcessing,
                                    refreshData: _vm.refreshData,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            inst.panels_info.class === "Forwarder"
                              ? _c("Forwarder", {
                                  attrs: {
                                    instance: inst,
                                    siloData: _vm.siloData,
                                    active: _vm.active,
                                  },
                                  on: {
                                    next: _vm.next,
                                    prev: function ($event) {
                                      return _vm.$emit("prev")
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      }
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "conditional__buttons r r--halign-center",
                      },
                      [
                        !_vm.hideButton(_vm.instance.if_panels)
                          ? _c(
                              "button",
                              {
                                staticClass: "ms__next btn btn--solid",
                                on: { click: _vm.next },
                              },
                              [_vm._v("Next >")]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.isAnswerThis
              ? _c(
                  "div",
                  {
                    staticClass:
                      "conditional_response__container conditional__response__container--else v--20",
                  },
                  [
                    _vm._l(
                      _vm.instance.else_panels.widgets,
                      function (inst, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "conditional__panel" },
                          [
                            inst.panels_info.class === "Kudo"
                              ? _c("Kudo", {
                                  attrs: {
                                    instance: inst,
                                    siloData: _vm.siloData,
                                    active: _vm.active,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            inst.panels_info.class === "Text_Panel"
                              ? _c("PanelText", {
                                  attrs: {
                                    instance: inst,
                                    siloData: _vm.siloData,
                                    active: _vm.active,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            inst.panels_info.class === "Form"
                              ? _c("PanelForm", {
                                  attrs: {
                                    instance: inst,
                                    siloData: _vm.siloData,
                                  },
                                  on: {
                                    next: _vm.next,
                                    processing: _vm.setProcessing,
                                    refreshData: _vm.refreshData,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            inst.panels_info.class === "Forwarder"
                              ? _c("Forwarder", {
                                  attrs: {
                                    instance: inst,
                                    siloData: _vm.siloData,
                                    active: _vm.active,
                                  },
                                  on: {
                                    next: _vm.next,
                                    prev: function ($event) {
                                      return _vm.$emit("prev")
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      }
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "conditional__buttons r r--halign-center",
                      },
                      [
                        !_vm.hideButton(_vm.instance.else_panels)
                          ? _c(
                              "button",
                              {
                                staticClass: "ms__next btn btn--solid",
                                on: { click: _vm.next },
                              },
                              [_vm._v("Next >")]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
                  2
                )
              : _vm._e(),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }