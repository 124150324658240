<template>
    <div class="likert v--25">

        <!--
        {{ field }}
        <br/>
        <br/>
        {{ pulledInputs }}
        <br/>
        <br/>
        {{ existingData }}
        -->

        <p v-if="field.field_label"
            v-html="field.field_label">
        </p>

        <div ref="parent" class="input input--buttons v--25">
            <div v-for="(f, index) in pulledInputs" :key="index" class="input input--buttons v--20">
                <label class="type--label type--h3" v-html="f"></label>
                <div class="field__radio-group c c--content--pad">
                    <div class="field__options v--15">

                        <div v-for="(option, optionIndex) in predefinedOptions" :key="optionIndex" class="option">
                            <input
                                class="radio"
                                type="radio"
                                :id="`${index}-${optionIndex}-${option.value}`"
                                :name="`${index}-${field.panels_info.widget_id}`"
                                :value="option.value"
                                :data-push-key="field.push_key"
                                :data-label="f"
                                :checked="getChecked(f, index, option.value)"
                                @change="onChangeEvent($event)"
                                data-watch-event="change"
                                data-pristine-not-blank
                                required
                            />
                            <label :for="`${index}-${optionIndex}-${option.value}`">
                                <span class="type--option" v-text="option.label"></span>
                            </label>
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                predefinedOptions: [
                    {
                        value: 1,
                        label: "Didn't work"
                    },
                    {
                        value: 2,
                        label: "Worked a little"
                    },
                    {
                        value: 3,
                        label: "Worked well enough"
                    },
                    {
                        value: 4,
                        label: "Worked pretty well"
                    },
                    {
                        value: 5,
                        label: "Worked very well"
                    },
                ]
            }
        },
        props: {
            field: Object,
            siloData: Object,
        },
        computed: {
            pulledInputs() {
                return this.field.pull_input_data !== "" ? this.siloData.user.modules[this.field.pull_input_data] : null;
            },
            existingData() {
                return this.field.push_key !== "" ? this.siloData.user.modules[this.field.push_key] : null;
            },
        },
        methods: {
            onChangeEvent(event) {
                // validate field
                this.$emit('validate');
            },  
            getChecked(originalAnswer, pulledIndex, optionValue) {
                
                // Check to make sure this index even still exists

                if (this.existingData[pulledIndex] === undefined) { return; }

                // Check to make sure the answer we have is what is stored in the database
                // This is necessary because you can change the answers by going backwards
                // Which can cause the answers displayed here not to match up with what is stored in the
                // database.

                if (originalAnswer !== this.existingData[pulledIndex]['title']) { return; }

                //console.log("checking checked", this.existingData[pulledIndex]['rating'] + " " + optionValue);
                return parseInt(this.existingData[pulledIndex]['rating']) === parseInt(optionValue) ? true : false;
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>