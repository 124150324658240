<template>
  <div class="ms ms--intro bg-color--dark" :data-id="index">
    <div class="ms__wrap c c--content--pad r r--justify-center r--halign-center pad--h">
      <div class="ms__top">
        <div class="ms__icon">
          <IconIntro :number="sd.order"/>
        </div>
        <div class="ms__content type--align-center">
          <div class="ms__count">Module {{sd.order}}</div>
          <h1 class="ms__title">{{sd.title}}</h1>
        </div>
      </div>
      <div class="ms__buttons r r--halign-center">
        <button v-if="slides.length > 1" class="ms__next btn btn--dark" @click="next">Let's Go!</button>
      </div>
    </div>
    <div class="ms__bg"></div>
  </div>
</template>

<script>
import IconIntro from '@c/IconIntro.vue';
export default {
  components: {
    IconIntro,
  },
	props: {
		sd: Object, // aka 'slide data'
    index: Number, // the index of the slide
    slides: Array, // all slide data
	},
  methods: {
      next() {
          // emit next event to parent
          this.$emit('next', '');
      }
  }
}
</script>

<style lang="scss">
.ms__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
</style>

<style lang="scss" scoped>
@import "@s/_mixins.scss";
@import "@s/_vars.scss";
.ms__wrap {
  padding-top: rem(30px);
}
.ms__title {
  font-size: rem(28px);
  line-height: lh(28px, 32px);
  font-weight: $weight_bold;
  letter-spacing: 0.34px;
  margin-top: rem(5px);
}
.ms__count {
  font-size: rem(14px);
  line-height: lh(14px, 18px);
  font-weight: $weight_bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.ms__content {
  margin-top: rem(30px);
}
.ms__buttons {
  margin-top: rem(65px);
}
</style>