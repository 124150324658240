var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "icon-session" },
    _vm._l(_vm.icons, function (ic, index) {
      return ic === _vm.name
        ? _c("div", {
            key: index,
            staticClass: "fill-current",
            domProps: {
              innerHTML: _vm._s(
                require(`!!html-loader!@icons/session/${ic}.svg`).default
              ),
            },
          })
        : _vm._e()
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }