import { render, staticRenderFns } from "./Badge.vue?vue&type=template&id=5f9b56b7&"
import script from "./Badge.vue?vue&type=script&lang=js&"
export * from "./Badge.vue?vue&type=script&lang=js&"
import style0 from "./Badge.vue?vue&type=style&index=0&id=5f9b56b7&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/nick/Local/kicknic/app/public/wp-content/themes/kicknic/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5f9b56b7')) {
      api.createRecord('5f9b56b7', component.options)
    } else {
      api.reload('5f9b56b7', component.options)
    }
    module.hot.accept("./Badge.vue?vue&type=template&id=5f9b56b7&", function () {
      api.rerender('5f9b56b7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/scripts/components/panels/Badge.vue"
export default component.exports