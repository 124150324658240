import Vue from 'vue'
import AppManager from './components/AppManager.vue'
//import ExampleComponent from './components/ExampleComponent.vue'
import LoginForm from './components/LoginForm.vue'
import { utils } from './modules/utils';

// NEED TO KEEP THESE CALENDARS IN HERE
import VCalendar from 'v-calendar';
// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
    // componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});


const mgmt = new Vue({
    el: '#mgmt',
    name: "App Manager App",
    components: { AppManager },
    data() {
        return {
            siloData: utils.get_data(),
        }
    },
    mounted() {
        window.mgmt = this;
        document.addEventListener("ajax-initialized", () => {
            this.fetchSiloData();
        });
        document.addEventListener("ajax-refreshed", () => {
            this.fetchSiloData();
        });
        this.makeSureWereLoggedIn();
    },
    watch: {
        siloData() {
            this.makeSureWereLoggedIn();
        }
    },
    methods: {
        fetchSiloData() {
            console.log('fetching silo data');
            this.siloData = utils.get_data();
        },
        makeSureWereLoggedIn() {
            // If we're logged in, just ignore this
            if (this.siloData.user.id !== 0) return;

            // If we're on the login page already, just ignore this
            if (window.location.href === this.siloData.urls.start) return;

            window.location.href = this.siloData.urls.start;
        }
    },
    // This needs to be done this way in order to pass props
    template: 
        `<app-manager v-bind:siloData="siloData"></app-manager>`
});

const lf = new Vue({
    el: '#lf',
    name: "Login Form App",
    components: { LoginForm },
});


