var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "showhide", attrs: { "data-active": _vm.active } },
    [
      _c("button", { on: { click: _vm.toggle } }, [
        _c("h2", { staticClass: "showhide__title type--h2" }, [
          _c("span", {
            staticClass: "showhide__title__indicator",
            domProps: { textContent: _vm._s(_vm.active ? "-" : "+") },
          }),
          _vm._v(" "),
          _c("span", {
            staticClass: "showhide__title__text",
            domProps: { textContent: _vm._s(_vm.shTitle) },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { ref: "container", staticClass: "showhide__content" }, [
        _c("div", {
          ref: "inner",
          staticClass:
            "showhide__content__inner type--content-links type--content-margins type--content-styles",
          domProps: { innerHTML: _vm._s(_vm.shContent) },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }