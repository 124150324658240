var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.siloData.user.id !== 0
    ? _c("span", { staticClass: "card", attrs: { "data-state": _vm.state } }, [
        _c(
          "div",
          {
            staticClass: "card__mover",
            style: `transition-duration: ${_vm.loadModule}`,
          },
          [
            _c(
              "div",
              { staticClass: "card__container" },
              [
                _c("EntryHeaderCard", {
                  attrs: { title: _vm.activator },
                  on: {
                    close: function ($event) {
                      return _vm.close()
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { class: _vm.hasChildren === false ? "ms ms--regular" : "" },
                  [
                    _c(
                      "div",
                      {
                        class:
                          _vm.hasChildren === false
                            ? "ms__wrap c c--content--pad r pad--h v--40"
                            : "",
                      },
                      [
                        _vm.activator === "help"
                          ? _c("Help", {
                              attrs: {
                                siloData: _vm.siloData,
                                appState: _vm.appState,
                                active:
                                  _vm.activator === "help" &&
                                  _vm.state === "active"
                                    ? true
                                    : false,
                              },
                              on: {
                                processing: _vm.setProcessing,
                                refreshData: _vm.refreshData,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.activator === "tasks"
                          ? _c("Tasks", {
                              attrs: {
                                siloData: _vm.siloData,
                                appState: _vm.appState,
                                active:
                                  _vm.activator === "tasks" &&
                                  _vm.state === "active"
                                    ? true
                                    : false,
                              },
                              on: {
                                processing: _vm.setProcessing,
                                refreshData: _vm.refreshData,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.activator === "plan"
                          ? _c("Plan", {
                              attrs: {
                                siloData: _vm.siloData,
                                active:
                                  _vm.activator === "plan" &&
                                  _vm.state === "active"
                                    ? true
                                    : false,
                              },
                              on: {
                                processing: _vm.setProcessing,
                                refreshData: _vm.refreshData,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "ms__bg" }),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }