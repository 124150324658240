var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "parent", staticClass: "tasks" }, [
    _c(
      "div",
      {
        staticClass:
          "text type--content-styles type--content-margins type--content-links v--25",
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "form",
          {
            ref: "form",
            staticClass: "form form--v required v--50",
            attrs: { action: "", method: "post" },
          },
          [
            _c("div", { staticClass: "form__fields r" }, [
              _c("div", { staticClass: "input input--buttons v--25" }, [
                _c("div", { staticClass: "input input--checkboxes v--25" }, [
                  _c(
                    "div",
                    { staticClass: "field__checkbox-group c c--content--pad" },
                    [
                      _c(
                        "div",
                        { staticClass: "field__options" },
                        _vm._l(_vm.tasks, function (task, index) {
                          return task.ready
                            ? _c("div", { key: index, staticClass: "option" }, [
                                _c("input", {
                                  staticClass: "checkbox",
                                  attrs: {
                                    type: "checkbox",
                                    id: `task-${index}`,
                                    name: `task-${index}-field`,
                                    value: "1",
                                    "data-label": task.label,
                                    "data-push-key": task.pushKey,
                                  },
                                  domProps: { checked: task.isChecked },
                                  on: {
                                    change: function ($event) {
                                      return _vm.submit($event)
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  { attrs: { for: `task-${index}` } },
                                  [
                                    _c("span", {
                                      staticClass: "type--option",
                                      domProps: {
                                        textContent: _vm._s(task.label),
                                      },
                                    }),
                                  ]
                                ),
                              ])
                            : _vm._e()
                        }),
                        0
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tasks__top" }, [
      _c(
        "h2",
        {
          staticClass:
            "header text__title icon icon--tasks r r--row-always r--halign-center",
        },
        [_vm._v("\n                My Tasks\n            ")]
      ),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Each session will ask if you practiced your plan from the previous session. If you did, great! It will automatically\n            check off here. If not, then you can always come here and check it off once you complete it."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }