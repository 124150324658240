var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _vm.siloData.user.id !== 0
          ? _c(
              "nav",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.appState === "home",
                    expression: "appState === 'home'",
                  },
                ],
                staticClass: "tools",
                attrs: { id: "tools" },
              },
              [
                _c(
                  "ul",
                  {
                    staticClass:
                      "tools__list c c--content--pad r r--row-always r--justify-space-between r--halign-center pad--h",
                  },
                  [
                    _c("li", [
                      _c(
                        "button",
                        {
                          staticClass:
                            "tools__list__button r r--row-always r--halign-center",
                          attrs: { onclick: "vapetracker.open()" },
                        },
                        [
                          _c("Icon", { attrs: { name: "icon-tools-track" } }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Track")]),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "button",
                        {
                          staticClass:
                            "tools__list__button r r--row-always r--halign-center",
                          on: {
                            click: function ($event) {
                              return _vm.$emit("updateState", "help")
                            },
                          },
                        },
                        [
                          _c("Icon", { attrs: { name: "icon-tools-help" } }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Crave/Slip")]),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "button",
                        {
                          staticClass:
                            "tools__list__button r r--row-always r--halign-center",
                          on: {
                            click: function ($event) {
                              return _vm.$emit("updateState", "tasks")
                            },
                          },
                        },
                        [
                          _c("Icon", { attrs: { name: "icon-tools-task" } }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Tasks")]),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "button",
                        {
                          staticClass:
                            "tools__list__button r r--row-always r--halign-center",
                          on: {
                            click: function ($event) {
                              return _vm.$emit("updateState", "plan")
                            },
                          },
                        },
                        [
                          _c("Icon", { attrs: { name: "icon-tools-plan" } }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Plan")]),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }