<template>
    <div class="conditional">
        
        <!-- * IF/ELSE -->
        <div v-if="type === 'ifelse'" class="conditional__response conditional__response--ifelse v--25">
            
            <div v-if="isAnswerThis" class="conditional__response__container conditional__response__container--if v--20">
                <!-- fill in if panels here -->
                <div v-for="(inst, index) in instance.if_panels.widgets" :key="index" class="conditional__panel">
                    <Kudo v-if="inst.panels_info.class === 'Kudo'" :instance="inst" :siloData="siloData" :active="active" />
                    <PanelText v-if="inst.panels_info.class === 'Text_Panel'" :instance="inst" :siloData="siloData" :active="active" />
                    <PanelForm v-if="inst.panels_info.class === 'Form'" :instance="inst" :siloData="siloData" @next="next" @processing="setProcessing" @refreshData="refreshData" />
                    <Forwarder v-if="inst.panels_info.class === 'Forwarder'" :instance="inst" :siloData="siloData" :active="active" @next="next" @prev="$emit('prev')" />
                </div>

                <!-- Need to include a next button if there is no form panel or team video panel -->
                <div class="conditional__buttons r r--halign-center">
                    <button v-if="!hideButton(instance.if_panels)" class="ms__next btn btn--solid" @click="next">Next ></button>
                </div>
            </div>

            <div v-if="!isAnswerThis" class="conditional_response__container conditional__response__container--else v--20">
                <!-- fill in else panels here -->
                <div v-for="(inst, index) in instance.else_panels.widgets" :key="index" class="conditional__panel">
                    <Kudo v-if="inst.panels_info.class === 'Kudo'" :instance="inst" :siloData="siloData" :active="active" />
                    <PanelText v-if="inst.panels_info.class === 'Text_Panel'" :instance="inst" :siloData="siloData" :active="active" />
                    <PanelForm v-if="inst.panels_info.class === 'Form'" :instance="inst" :siloData="siloData" @next="next" @processing="setProcessing" @refreshData="refreshData" />
                    <Forwarder v-if="inst.panels_info.class === 'Forwarder'" :instance="inst" :siloData="siloData" :active="active" @next="next" @prev="$emit('prev')" />
                </div>

                <!-- Need to include a next button if there is no form panel or team video panel -->
                <div class="conditional__buttons r r--halign-center">
                    <button v-if="!hideButton(instance.else_panels)" class="ms__next btn btn--solid" @click="next">Next ></button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import PanelText from "@c/panels/PanelText.vue";
import PanelForm from "@c/panels/PanelForm.vue";
import Forwarder from "@c/panels/Forwarder.vue";
import Kudo from "@c/panels/Kudo.vue";
export default {
    components: {
        PanelText,
        PanelForm,
        Forwarder,
        Kudo,
    },
    data() {
        return {
            //processing: this.instance.type !== "empty" ? true : false,
            type: this.instance.type === undefined ? "ifelse" : this.instance.type,
        }
    },        
    props: {
        siloData: Object,
        instance: Object,
        active: Boolean, 
    },
    computed: {
        answer() {
            return this.siloData.user.modules[this.instance.check_field] ? this.siloData.user.modules[this.instance.check_field] : null;
        },
        isAnswerThis() {
            // If this is an array, compare it against the first item in the array
            if (Array.isArray(this.answer)) {
                return this.answer[0] === this.instance.if_equals_text ? true : false;
                // Otherwise, just compare it against the string
            } else {
                return this.answer === this.instance.if_equals_text ? true : false;
            }
        },
    },
    methods: {
        next() {
            this.$emit('next');
        },
        setProcessing(state) {
            this.$emit('processing', state);
        },
        refreshData(onComplete = null) {
            this.$emit('refreshData', onComplete);
        },
        playingVideo() {
            this.$emit('playingVideo');
        },
        hideButton(panels_array) {
            let returned = false;

            // Loop through panel widgets
            panels_array.widgets.forEach(instance => {
                // If we find a form panel, set the return to true
                if (instance.panels_info.class === 'Form' || instance.panels_info.class === 'Video_Team_Panel') {
                    returned = true;
                }
            });

            return returned;
        },
    }

}
</script>

<style lang="scss" scoped>

</style>