var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.checkToHide === false
    ? _c(
        "div",
        { ref: "parent", staticClass: "input input--checkboxes v--25" },
        [
          _vm.field.field_label
            ? _c("label", {
                class: _vm.labelClass,
                domProps: { innerHTML: _vm._s(_vm.field.field_label) },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "field__checkbox-group c c--content--pad",
              class: _vm.field.is_optional
                ? "field--optional"
                : "field--required",
              attrs: { "data-push-key": _vm.field.push_key },
            },
            [
              _c(
                "div",
                { ref: "field", staticClass: "field__options" },
                [
                  _vm._l(_vm.field.checkboxes, function (f, index) {
                    return _c(
                      "div",
                      { key: `${index}s`, staticClass: "field__wrap" },
                      [
                        f.header
                          ? _c("h3", {
                              staticClass: "field__header",
                              staticStyle: { "flex-basis": "100%" },
                              domProps: { textContent: _vm._s(f.header) },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "option",
                            class: _vm.isOtherParent(f.is_other),
                          },
                          [
                            _c("input", {
                              staticClass: "checkbox",
                              class: _vm.isOtherInput(f.is_other),
                              attrs: {
                                "data-clearer": f.is_clearer,
                                type: "checkbox",
                                id: _vm.inputId(index + "-st"),
                                name: _vm.field.panels_info.widget_id,
                                "data-push-key": _vm.field.push_key,
                                min: _vm.field.min_count,
                                max: _vm.field.max_count,
                                "data-watch-event": "change",
                                "data-pristine-not-blank": "",
                                required: !_vm.field.is_optional,
                                optional: _vm.field.is_optional,
                              },
                              domProps: {
                                value: f.is_other ? "" : f.checkbox,
                                checked: _vm.getChecked(f.checkbox),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.onChangeEvent($event)
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: _vm.inputId(index + "-st") } },
                              [
                                _c("span", {
                                  staticClass: "type--option",
                                  domProps: { textContent: _vm._s(f.checkbox) },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            f.is_other
                              ? _c("input", {
                                  staticClass: "input--other",
                                  attrs: {
                                    type: "text",
                                    placeholder:
                                      "Enter text here (60 character max)",
                                    maxlength: "60",
                                    name: "",
                                    value: "",
                                  },
                                  on: {
                                    keyup: function ($event) {
                                      return _vm.setOtherValue($event)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm._l(_vm.pulledInputs, function (f, index) {
                    return _vm.pulledInputs !== undefined
                      ? _c("div", { key: `${index}d`, staticClass: "option" }, [
                          _c("input", {
                            staticClass: "checkbox",
                            attrs: {
                              type: "checkbox",
                              id: _vm.inputId(index + "-dy"),
                              name: _vm.field.panels_info.widget_id,
                              "data-push-key": _vm.field.push_key,
                              min: _vm.field.min_count,
                              max: _vm.field.max_count,
                              "data-watch-event": "change",
                              "data-pristine-not-blank": "",
                              required: !_vm.field.is_optional,
                              optional: _vm.field.is_optional,
                            },
                            domProps: { value: f, checked: _vm.getChecked(f) },
                            on: {
                              change: function ($event) {
                                return _vm.onChangeEvent($event)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            { attrs: { for: _vm.inputId(index + "-dy") } },
                            [
                              _c("span", {
                                staticClass: "type--option",
                                domProps: { textContent: _vm._s(f) },
                              }),
                            ]
                          ),
                        ])
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  _vm.field.include_other
                    ? _c("div", { staticClass: "option option--other" }, [
                        _c("input", {
                          staticClass: "checkbox other-activator",
                          attrs: {
                            type: "checkbox",
                            id: _vm.inputId(
                              _vm.pulledInputs.length + 1 + "-dy"
                            ),
                            name: _vm.field.panels_info.widget_id,
                            value: "",
                            "data-push-key": _vm.field.push_key,
                            min: _vm.field.min_count,
                            max: _vm.field.max_count,
                            "data-watch-event": "change",
                            "data-pristine-not-blank": "",
                            required: !_vm.field.is_optional,
                            optional: _vm.field.is_optional,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onChangeEvent($event)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            attrs: {
                              for: _vm.inputId(
                                _vm.pulledInputs.length + 1 + "-dy"
                              ),
                            },
                          },
                          [
                            _c("span", { staticClass: "type--option" }, [
                              _vm._v("Somethign else [write in]"),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "input--other",
                          attrs: {
                            type: "text",
                            placeholder: "Enter text here (60 character max)",
                            maxlength: "60",
                            name: "",
                            value: "",
                          },
                          on: {
                            keyup: function ($event) {
                              return _vm.setOtherValue($event)
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.field.include_clearer
                    ? _c("div", { staticClass: "option option--clearer" }, [
                        _c("input", {
                          staticClass: "checkbox",
                          attrs: {
                            type: "checkbox",
                            "data-clearer": "true",
                            id: _vm.inputId(
                              _vm.pulledInputs.length + 2 + "-dy"
                            ),
                            name: _vm.field.panels_info.widget_id,
                            "data-push-key": _vm.field.push_key,
                            min: _vm.field.min_count,
                            max: _vm.field.max_count,
                            "data-watch-event": "change",
                            "data-pristine-not-blank": "",
                            required: !_vm.field.is_optional,
                            optional: _vm.field.is_optional,
                          },
                          domProps: { value: _vm.field.clearer_name },
                          on: {
                            change: function ($event) {
                              return _vm.onChangeEvent($event)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            attrs: {
                              for: _vm.inputId(
                                _vm.pulledInputs.length + 2 + "-dy"
                              ),
                            },
                          },
                          [
                            _c("span", {
                              staticClass: "type--option",
                              domProps: {
                                innerHTML: _vm._s(_vm.field.clearer_name),
                              },
                            }),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }