<template>
    <div class="embed r v--25">
        <div v-if="instance.title || instance.content" class="embed__top v--15">
            <h2 v-if="instance.title" v-html="instance.title" class="embed__title type--h2"></h2>
            <div v-if="instance.content" class="embed__content type--content-links type--content-margins type--content-styles" v-html="instance.content"></div>
        </div>
        <div ref="videoParent" class="embed__video embed-container">        
            <iframe 
                ref="video" 
                :src="src"
                allow="autoplay; fullscreen; encrypted-media; autopause; playsinline;" 
                allowfullscreen="" 
                width="500" 
                height="281"
                frameborder="0"
                >
            </iframe>
        </div>
        <div v-if="allowNext === true && instance.required === true" class="embed__footer r r--justify-center r--halign-center v--30 type--align-center">
            <transition name="button">
                <button v-if="isValid && !instance.last" class="btn btn--solid" @click="$emit('next')">
                    Next >
                </button>
                <button v-if="isValid && instance.last" class="btn btn--solid" @click="$emit('close')">
                    Home
                </button>
            </transition>
        </div> 
    </div>
</template>

<script>
    export default {
        data() {
            return {
                watched: false,
                isValid: false,
                player: null,
            }
        },
        props: {
            appState: String,
            instance: Object,
            pauseVideo: Boolean,
            active: Boolean,
            allowNext: Boolean,
        },
        watch: {
            appState() {
                if (this.appState === "home") {
                    this.pause();
                }
            },
            pauseVideo() {
                // When we are told to pause the video by the parent component, just do it
                if (this.pauseVideo === true) {
                    this.pause();
                }
            },
            active() {
                // When this comes active, start everything back up from default
                if (this.active === true) {
                    this.reset();
                    this.init();
                    this.validate();

                    /*
                    setTimeout(() => {
                        this.$refs.videoParent.style.width = "101%";
                        this.$refs.video.style.width = "101%";
                        setTimeout(() => {
                            this.$refs.videoParent.style.width = "100%";
                            this.$refs.video.style.width = "100%";
                        }, 1);
                    }, 1);
                    */
                } else {
                    /*
                    this.$nextTick(() => {
                        this.$refs.videoParent.style.width = "100%";
                        this.$refs.video.style.width = "100%";
                    });
                    */
                    this.pause();
                }
            }
        },
        computed: {
            src() {
                // This is how the url should look in the end
                // https://player.vimeo.com/video/742345068?h=fc79be6ce6&amp;dnt=1&amp;app_id=122963
                // oEmbed sdk: https://developer.vimeo.com/api/oembed/videos
                let url = this.instance.embed_url;
                var match = url.split("/");

                if (match) {
                    return `https://player.vimeo.com/video/${match[3]}?h=${match[4]}&dnt=1&app_id=122963&pip=false&autopause=true&width=500&height=281&maxwidth=600&dnt=true&responsive=true`;
                } else {
                    return "";
                }
            },
        },
        mounted() {
            this.reset();
            this.init();
            this.validate();
        },
        destroyed() {
            this.reset();
        },
        methods: {
            // Button click to pause
            pause() {
                if (this.player === null) { return; }
                this.player.pause();
                this.player.unload(); // set the video back to initial state
            },
            // Button click to play
            /*
            play() {
                this.player.play();
                this.playing();
                this.watched = true;
            },
            */
           // Validate if it's required
            validate() {
                if (this.instance.required === true) {
                    if (this.watched === true) {
                        this.isValid = true;
                    } else {
                        this.isValid = false;
                    }
                } else {
                    this.isValid = true;
                }
            },
            // What to do when we play the video
            playing() {
                // Tell parent we are playing a video
                this.$emit('playingVideo');

                if (this.watched === false) {
                    this.watched = true;
                    // Fire watched event
                    this.postVideoWatched();
                }

                this.validate();
            },
            // Load video player
            init() {
                this.player = new Vimeo.Player(this.$refs.video);
                this.player.on('play', this.playing);
            },
            // Resets player to default
            reset() {
                if (this.player !== null) {
                    this.player.off('play', this.playing);
                    this.player = null;
                }
                this.watched = false;
                this.isValid = false;
            },
            postVideoWatched() {
                let data = {
                    type: 'watched',
                    title: this.instance.embed_url,
                };
                window.events.postAction(data);
            },
        }
    }
</script>

<style lang="scss" scoped>
@import "@s/_mixins.scss";
@import "@s/_vars.scss";
.embed__footer {
    margin-top: rem(35px);
}
</style>