var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "kudo" }, [
    _c("div", {
      ref: "kudo",
      staticClass: "kudo__icon r r--justify-center r--halign-center",
      domProps: {
        innerHTML: _vm._s(
          require(`!!html-loader!@icons/kudo/kudos-${_vm.selectedKudo}.svg`)
            .default
        ),
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }