<template>
    <span>
    </span>
</template>

<script>
    const axios = require('axios');
    export default {
        props: {
            siloData: Object
        },
        mounted() {
            console.log('event tracker mounted');
            // Assign this globally so it can be used anywhere
            window.events = this;
        },
        methods: {
            // Track a page or a module / module slide view
            postPageView(data) {
                axios.post(this.siloData.urls.eventpageview, data);
            },
            // Track data submitted
            postSubmitData(data) {
                axios.post(this.siloData.urls.eventsubmit, data);
            },
            // Track actions like clicks and views
            postAction(data) {
                axios.post(this.siloData.urls.eventaction, data);
            }
        }
    }
</script>