var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "ms ms--intro bg-color--dark",
      attrs: { "data-id": _vm.index },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "ms__wrap c c--content--pad r r--justify-center r--halign-center pad--h",
        },
        [
          _c("div", { staticClass: "ms__top" }, [
            _c(
              "div",
              { staticClass: "ms__icon" },
              [_c("IconIntro", { attrs: { number: _vm.sd.order } })],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ms__content type--align-center" }, [
              _c("div", { staticClass: "ms__count" }, [
                _vm._v("Module " + _vm._s(_vm.sd.order)),
              ]),
              _vm._v(" "),
              _c("h1", { staticClass: "ms__title" }, [
                _vm._v(_vm._s(_vm.sd.title)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "ms__buttons r r--halign-center" }, [
            _vm.slides.length > 1
              ? _c(
                  "button",
                  {
                    staticClass: "ms__next btn btn--dark",
                    on: { click: _vm.next },
                  },
                  [_vm._v("Let's Go!")]
                )
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "ms__bg" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }