<template>
    <div class="icon-intro">
        <div
            v-for="n in moduleCount"
            v-if="number === n"
            v-html="require(`!!html-loader!@icons/icon-module-${n}-i.svg`).default" 
            class="icon-intro__icon"
        ></div>
    </div>
</template>

<script>
// The idea for this came from here: https://gist.github.com/calebporzio/623c536f7fff77861b8a37ec11a1d3da
    export default {
        data() {
            return {
                moduleCount: 7,
            }
        },
        props: {
            number: Number,
        },
        mounted() {

        },
    }
</script>