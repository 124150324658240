<template>
    <div class="vm-holder" style="display:none;">
        <div ref="outer" id="vm" class="vm anim" aria-hidden="true">
            <div class="vm__content__container">
                <div ref="parent" id="vm__video-player" class="embed-container unready" width="1280" height="720">
                    <div class="vm__video-player__loading" width="1280" height="720">
                        <Icon name="kicknic-logo-new" />
                    </div>
                    <div ref="parent_video" id="video-player-vimeo" class="video-player video-player--vimeo aspect-ratio--video"
                        aria-hidden="true"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@c/Icon.vue';
import Vimeo from '../../library/scripts/vimeo-player.js';
    export default {
        components: {
            Icon,
        },
        data() {
            return {
                modal_args: {
                    message_is_element: true,
                    additional_classes: "video",
                    message: '.vm',
                    button: false,
                    onClose: this.clearPlayer,
                },
                player: {
                    vimeo: null,
                    is_ready: false,
                },
                highlight: "765fd3",
                currently_playing: null, // I might be able to remove this
            }
        },
        props: {
            appState: String,
            siloData: Object
        },
        mounted() {
            window.videoPlayer = this;
        },
        methods: {
            addHandlers() {
                document.addEventListener('modal-close', ()=>{
                    if (this.current_playing != null) {
                        this.clearPlayer();
                    }
                });
            },
            buildVimeoApi(new_id, play_on_ready, element_to_load_video_in) {
                let player_id = new_id ? new_id : 478928746; // change this default id
                let player_options = {
                    id: player_id,
                    player_id: player_id,
                    color: this.highlight,
                    portrait: true,
                    title: true,
                    controls: true,
                    width: 1280,
                    height: 720,
                    autopause: true,
                    api: true,
                    muted: false,
                    playsinline: false, // This needs to be true D:
                    allowfullscreen: true,
                    //origin: window.location.protocol + "//" + window.location.host,
                    //autoplay: true,
                };

                //element_to_load_vimeo_in = document.querySelector('.video-player');


                this.player.vimeo = new Vimeo(
                    element_to_load_video_in,
                    player_options
                );


                if (play_on_ready && element_to_load_video_in !== null && element_to_load_video_in !== undefined) {
                    this.player.vimeo.ready().then(() => {

                        let iframe = element_to_load_video_in.querySelector("iframe");
                        iframe.id = player_id;

                        // These may need to be changed to jquery objects
                        iframe.setAttribute("webkitallowfullscreen", "true");
                        iframe.setAttribute("mozallowfullscreen", "true");
                        iframe.setAttribute("allowfullscreen", "true");

                        this.setPlayerReady();
                        this.player.vimeo.play();
                    });
                }
            },
            // Probably don't need
            playVideo(element, event) {
                event.preventDefault();
                this.setCurrentlyPlaying(
                    element.attr('data-video-type'),
                    element.attr('data-video-id')
                );
            }, 
            playVideoOnClick(type, id) {
                this.setCurrentlyPlaying(type, id);
            },
            setCurrentlyPlaying(type, id) {
                this.currently_playing = type;
                window.modal.set(this.modal_args);
                this.buildVimeoApi(id, true, this.$refs.parent_video);
            },
            clearPlayer() {
                this.player.vimeo.destroy();
                this.currently_playing = null;
                this.setPlayerUnready();
            },
            setPlayerUnready() {
                this.player.is_ready = false;
                // TODO: Set this up as data attributes?
                this.$refs.parent.classList.add('unready');
                this.$refs.parent.classList.remove('ready');
                this.$refs.outer.classList.add('anim');
            },
            setPlayerReady() {
                this.player.is_ready = true;
                // TODO: Set this up as data attributes?
                this.$refs.parent.classList.remove('unready');
                this.$refs.parent.classList.add('ready');
                this.$refs.outer.classList.remove('anim');
            },
        },
    }
</script>

<style lang="scss">
@import "@s/_mixins.scss";
@import "@s/_vars.scss";
.modal[data-additional-classes="video"] {
    padding-left: 0;
    padding-right: 0;

    .modal__card {
        background-color: transparent;
        max-width: rem(720px + ($content_gutter_width * 2));
    }

    .vm__content__container {
        padding: rem($content_gutter_width) rem($content_gutter_width);
        background-color: transparent !important;
    }

    .embed-container {
        background-color: #000;
        padding-top: 0 !important;
    }

    .modal__close {
        top: rem(-$content_gutter_width);
        right: rem($content_gutter_width);

    }

    .modal__content__text {
        //padding: rem($gutter_width) rem($gutter_width);
        padding: 0;
    }
}
.vm__video-player__loading {
    position: absolute !important;
    display: flex;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    opacity: 0;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    background-color: #000;
    transition: opacity 0.15s;

    svg {
        max-width: 30%;
        opacity: 0.2;
        fill: $color_white;

        * {
            fill: $color_white;
        }
    }
}

.unready {
    .vm__video-player__loading {
        opacity: 1;

        svg {
            animation: color-me-in 2s 0.15s infinite;
        }
    }
}

.ready {
    .vm__video-player__loading {
        opacity: 0;
    }
}
</style>