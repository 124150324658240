<template>
    <div class="input input--date v--25">
       <!--{{field}}-->

        <label
            :class="hideLabel" 
            v-html="field.field_label">
        </label>
        
        <div class="field__date c c--content--pad">
            <v-calendar 
                :attributes='attributes' 
                :timezone="siloData.user.tz" 
                :min-date='minDate' 
                :max-date='maxDate' 
                @dayclick='dayClicked'
            ></v-calendar>
        </div>

        <!-- captures the value on the calendar -->
        <!-- 
            * used a a wrapper instead of a hidden field here 
            * because the validation on the hidden field didn't work
        -->
        <div style="display:none;">
            <input
                type="text"
                :value="days"
                :data-push-key="field.push_key"
                @change="$emit('validate')"
                data-watch-event="change"
                data-pristine-not-blank
                required
                >
        </div>

        <div style="display:none">
            <div ref="warning" class="warning-notice">
                <div class="r v--20">
                    <div class="warning__copy type--content-margins type--content-styles">
                        <h2 class="type--h2 type--color--purple">Please Note</h2>
                        <p>Choosing a date less than a week away will give you less time to prepare. Consider choosing a date over one week away.</p>
                    </div>
                    <div class="warning__buttons r r--halign-center r--valign-center r--justify-center v--15">
                        <button class="ms__next btn btn--solid" onclick="modal.close()">Ok</button>
                        <!-- <button class="ms__next btn btn--solid" @click="modal.close()">No</button> -->
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>

/**
* * ! How validation works:
* 1. There is a hidden field that has v-model of the days array
* 2. This field is required and validates as not blank
* 3. When you check off a calendar, at the end of the event, we
*    wait a tick for the days array to change and then we
*    fire off the validate event which is picked up by the form
*    component which checks to see if the hidden field is
*    filled in or not
*/

import Vue from 'vue'
import Calendar from 'v-calendar/lib/components/calendar.umd'

export default {
    components: {
		Calendar,
	},
    props: {
        field: Object,
        siloData: Object,
    },
    data() {
		return {
			state: "ready", // ready, complete
			processing: false, // true or false
			selectedDay: null, // currently selected date
			backgroundColor: '#4cd964', // background of circles
			color: '#000000', // text color on circles 
			days: [ this.siloData.user.modules.meta_vt_target_date ? this.siloData.user.modules.meta_vt_target_date : ""] // dates array
		}
	},
    computed: {
        dateForPost() {
            let dates = this.days.map(day => (
				day.id
			));
			return dates;  
        },
        // the day after today
        minDate() {
            let today = new Date();
            let days = 0;
            let oneWeekFromToday = new Date(today.getTime() + days * 24 * 60 * 60 * 1000);
            return oneWeekFromToday;
        },
        // two weeks from today
        maxDate() {
            let today = new Date();
            let twoWeeksFromToday = new Date(today.getTime() + 14 * 24 * 60 * 60 * 1000);
            return twoWeeksFromToday;
        },
        hideLabel() {
            return this.field.hide_label === true ? 'screen-reader-only' : '';
        },
        dates() {
			//return this.days.map(day => day.date);
			// This converts our days array into a calendar readable format

            let returned = [];

            if (this.days.length > 0 && this.days[0] != "") {
                returned = this.days.map(day => ({
                    id: day,
                    date: new Date(day),
                }));
            }

			return returned;
		},
        attributes() {
			// Take the value of data.dates
			// Create a new array of all those dates
			// Then make that array the v-model of the v-calendar

            let returned = [];
            
            if (this.dates.length > 0 && this.dates[0] != "") {
                returned = this.dates.map((date, index) => ({
                    key: `todo.${index}`,
                    highlight: {
                        style: {                     
                            background: this.backgroundColor
                        },
                        contentStyle: {              
                            color: this.color
                        }
                    },
                    // We need to add 12 hours here so that the day we click ends up being correct
                    dates: this.addHours(12, date.id),
                }));
            }

			return returned;
		},
    },
    methods: {
        // Check to see if the selected date is less than a week away
        isLessThanAWeekAway(selectedDate) {
            let today = new Date();
            // Get date one week away as a timestamp
            let oneWeekFromToday = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000).getTime();
            // Get timestamp from selected date
            let selectedDateTime = new Date(selectedDate.id).getTime();

            if (selectedDateTime < oneWeekFromToday) {
                return true;
            } else {
                return false;
            }
        },
        // This adds X hours to whatever date you push up
		addHours(numOfHours, date) {
			let date_obj = new Date(date);
			date_obj.setTime(date_obj.getTime() + numOfHours * 60 * 60 * 1000);
			return date_obj;
		},
		dayClicked(day) {

            // If the date is less than a week away show the warning modal
            if (this.isLessThanAWeekAway(day) === true) {
                console.log(`.${this.$refs.warning.classList[0]}`);
                modal.set({
                    message: `.${this.$refs.warning.classList[0]}`,
                    message_is_element: true,
                    hide_close: true,
                    button: false,
                    additional_classes:'notice-centered',
                })
            }

			// const idx = this.days.findIndex(d => d.id === day.id);
			// This had to be changed to compare the clicked ID against the days data array which has no ID
			const idx = this.days.findIndex(d => d === day.id);

			if (idx >= 0) {
				this.days.splice(idx, 1);
			} else {
                // Empty array
                this.days = [];
                // Add date into array
				this.days.push(day.id);
			}

            // Waits for days array updates before checking validation
            this.$nextTick(()=>{
                // Check validation when we check off a date
                this.$emit('validate');
            })
		},
    }, 
}
</script>
<style lang="">
    
</style>