var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", {
      staticClass: "fill-current",
      domProps: {
        innerHTML: _vm._s(
          require(`!!html-loader!@icons/${_vm.name}.svg`).default
        ),
      },
    })
  },
]
render._withStripped = true

export { render, staticRenderFns }