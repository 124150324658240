<template>
    <div ref="parent" v-if="checkToHide === false" class="input input--checkboxes v--25">
       <!--{{field}}-->

        <label
            v-if="field.field_label"
            :class="labelClass" 
            v-html="field.field_label">
        </label>
        
        <div class="field__checkbox-group c c--content--pad" :class="field.is_optional ? 'field--optional' : 'field--required'" :data-push-key="field.push_key">

            <!-- ! regular checkboxes -->
            <div ref="field" class="field__options">

                <div v-for="(f, index) in field.checkboxes" :key="`${index}s`" class="field__wrap">

                    <h3 v-if="f.header" class="field__header" style="flex-basis:100%;" v-text="f.header"></h3>

                    <div class="option " :class="isOtherParent(f.is_other)">
                        <input
                            class="checkbox"
                            :class="isOtherInput(f.is_other)" 
                            :data-clearer="f.is_clearer"
                            type="checkbox"
                            :id="inputId(index + '-st')"
                            :name="field.panels_info.widget_id"
                            :value="f.is_other ? '' : f.checkbox"
                            :data-push-key="field.push_key"
                            @change="onChangeEvent($event)"
                            :min="field.min_count"
                            :max="field.max_count"
                            data-watch-event="change" 
                            data-pristine-not-blank
                            :required="!field.is_optional"
                            :optional="field.is_optional"
                            :checked="getChecked(f.checkbox)"
                            />

                        <label 
                            :for="inputId(index + '-st')">
                            <span class="type--option" v-text="f.checkbox"></span>
                        </label>
                            
                        <input 
                            v-if="f.is_other"
                            type="text" 
                            class="input--other" 
                            placeholder="Enter text here (60 character max)" 
                            maxlength="60"
                            name=""
                            value=""
                            @keyup="setOtherValue($event)"
                            >
                    </div>        
                </div>
                

                <!-- ! dynamically pulled checkboxes -->
                <div 
                    v-if="pulledInputs !== undefined"
                    v-for="(f, index) in pulledInputs" :key="`${index}d`"
                    class="option ">
                    <input
                        class="checkbox"
                        type="checkbox"
                        :id="inputId(index + '-dy')"
                        :name="field.panels_info.widget_id"
                        :value="f"
                        :data-push-key="field.push_key"
                        @change="onChangeEvent($event)"
                        :min="field.min_count"
                        :max="field.max_count"
                        data-watch-event="change" 
                        data-pristine-not-blank
                        :required="!field.is_optional"
                        :optional="field.is_optional"
                        :checked="getChecked(f)"
                        />
                        <label 
                            :for="inputId(index + '-dy')">
                            <span class="type--option" v-text="f"></span>
                        </label>
                    </div>

                    <!-- ! if other for dynamically pulled inputs -->
                    <div v-if="field.include_other" class="option option--other">
                        <input 
                            class="checkbox other-activator" 
                            type="checkbox" 
                            :id="inputId(pulledInputs.length + 1 + '-dy')"
                            :name="field.panels_info.widget_id" 
                            value="" 
                            :data-push-key="field.push_key"
                            @change="onChangeEvent($event)" 
                            :min="field.min_count" 
                            :max="field.max_count" 
                            data-watch-event="change"
                            data-pristine-not-blank 
                            :required="!field.is_optional"
                            :optional="field.is_optional"
                            />
                        <!--find a better way to do this-->
                        <label :for="inputId(pulledInputs.length + 1 + '-dy')">
                            <span class="type--option">Somethign else [write in]</span>
                        </label>
                    
                        <input 
                            type="text" 
                            class="input--other" 
                            placeholder="Enter text here (60 character max)"
                            maxlength="60" 
                            name="" 
                            value="" 
                            @keyup="setOtherValue($event)">
                    </div>
                    <!-- ! end if other -->

                    <!-- ! if clearer for dynamic pull inputs -->
                    <div v-if="field.include_clearer" class="option option--clearer">
                        <input 
                            class="checkbox" 
                            type="checkbox" 
                            data-clearer="true"
                            :id="inputId(pulledInputs.length + 2 + '-dy')"
                            :name="field.panels_info.widget_id" 
                            :value="field.clearer_name" 
                            :data-push-key="field.push_key"
                            @change="onChangeEvent($event)" 
                            :min="field.min_count" 
                            :max="field.max_count" 
                            data-watch-event="change"
                            data-pristine-not-blank 
                            :required="!field.is_optional"
                            :optional="field.is_optional"
                            />
                        <!--find a better way to do this-->
                        <label :for="inputId(pulledInputs.length + 2 + '-dy')">
                            <span class="type--option" v-html="field.clearer_name"></span>
                        </label>
                    </div>
                    <!-- ! end clearer -->
                </div>


            </div>


        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            pulledInputs:  this.field.pull_input_data !== "" ? this.siloData.user.modules[this.field.pull_input_data] : null,
        }
    },
    props: {
        field: Object,
        siloData: Object,
    },
    computed: {
        labelClass() {
            let returned = "";
            
            if (this.field.hide_label === true) {
                returned += " screen-reader-only";
            }

            if (this.field.label_class !== undefined) {
                returned += " " + this.field.label_class;
            } else {
                returned += " type--label";
            }

            return returned;
        },
        checkToHide() {
            // Assume we don't want to hide
            let returned = false;
            // If we are pulling inputs start the check
            if (this.pulledInputs !== null && this.pulledInputs !== undefined) {
                // Pull the inputs
                let inputs = this.siloData.user.modules[this.field.pull_input_data];
                // If there are one or less inputs, hide everything because
                // it doesn't make sense to have just one checkbox (for now)
                if (inputs.length < 2) {
                    returned = true;
                }
            }
            return returned
        }
    },
    watch: {
      siloData() {
        // If we don't have pulled checkbox data set up, just return
        // Otherwise, this will wipe out all of our manually entered checkbox data on silo data refresh
        if (this.field.pull_input_data !== undefined) {
            this.pulledInputs = this.siloData.user.modules[this.field.pull_input_data];
        }

        // Loop through the values we have, if there is one that is 
        // unaccounted for, set the other field value to that
        this.$nextTick(()=>{
            this.fillOtherValue();
        });
      }, 
      field() {
        if (this.pulledInputs !== null) {
            this.$emit('validate');
        }
      }, 
    },
    mounted() {
        this.$nextTick(()=>{
            this.fillOtherValue();
        });
    },
    destroyed(){
        //console.log('Checkboxes Destroyed!');
        this.pulledInputs = null;
    },
    methods: {
        // Get a slug
        getSlug(str) {
            return window.utils.methods.sluggify(str)
        },
        // Compare our silo data answer against the value of the field
        // to see if we have already filled in an answer
        getChecked(val) {

            if (this.field.push_key === null) return null;
            if (this.siloData.user.modules[this.field.push_key] === "") return null;

            // Get the dynamic fields
            let fields = this.siloData.user.modules[this.field.push_key];
            
            // Establish the default return value
            let returned = null;

            // If the fields we check against is an array, loop through the array
            // This would most likely occur for a checkbox group
            if (Array.isArray(fields) === true) {
                fields.forEach(field => {
                    if (field === val) {
                        returned = 'checked="true"';
                    }
                });
            // Otherwise just check against the single field
            // This would most likely occur for a radio button group
            } else {
                if (fields === val) {
                    returned = 'checked="true"';
                }
            }
            return returned;
        },
        onChangeEvent(event) {
            console.log('and change event fired');
            // validate field
            this.$emit('validate');
            // check for other
            this.checkOther(event);
            // check the clearer
            this.checkClearer(event);
        },
        inputId(index) {
            return this.field.push_key + '-' + this.field.panels_info.widget_id + index;
        },
        checkClearer(event) {
            // First name sure the form actually has a clearer
            let parent = event.target.closest('.input');
            let clearer = parent.querySelector('[data-clearer="true"]');
            let inputs = parent.querySelectorAll('input');
            
            // If there is no clearer, just quit
            if (clearer === null || clearer === undefined) return;
 
            //console.log('clearer is here, figure out what to do');

            // If are checking off a clearer
            if (event.target.dataset.clearer === "true") {
                //console.log('clicked is clearer');

                // If we are checking off the clearer
                if (event.target.checked === true) {
                    // Uncheck everythign else
                    inputs.forEach(input => {
                        if (input.type === "checkbox" && input.dataset.clearer !== "true") {
                            input.checked = false;
                        }
                    });

                    // Disables the other field completely
                    this.otherSetDisabledSpecific();
                }

            // If we are checking off a non-clearer
            } else {
                //console.log('clicked is not clearer');
                // Clear the clearer
                clearer.checked = false;
            }
        },
        isOtherParent(val) {
            return val === true ? 'option--other' : '';
        },
        isOtherInput(val) {
            return val === true ? 'other-activator' : '';
        },
        checkOther(event) {
            if (!event.target.classList.contains('other-activator')) return;
            
            let checkbox = event.target;
            let parent = event.target.closest('.option--other');
            let other = parent.querySelector('.input--other');

            if (checkbox.checked === true) {
                this.otherSetActive(parent, checkbox, other);
            } else {
                this.otherSetDisabled(parent, checkbox, other);
            }
        },
        otherSetDisabled(parent, checkbox, other) {
            parent.classList.remove('active');
            checkbox.value = "";
            other.value = "";
        },
        otherSetDisabledSpecific() {
            // Make sure there is an other field
            if (this.$refs.parent.querySelector('.option--other') === null) return;

            let parent = this.$refs.parent.querySelector('.option--other');
            let checkbox = parent.querySelector('.other-activator');
            let other = parent.querySelector('.input--other');
            this.otherSetDisabled(parent, checkbox, other);
        },
        otherSetActive(parent, checkbox, other) {
            parent.classList.add('active');
            other.focus();
        },
        // Set the value of the inputs to be the same
        setOtherValue(event) {
            let other = event.target;
            let parent = other.closest('.option--other');
            let input = parent.querySelector('.other-activator');

            // set value of paired input to value of other
            input.value = other.value;

            // validate paired input against form validation rules
            this.$emit('validate');
        },
        // If this is an other field, check to see if there's a missing value and then fill it here
        fillOtherValue() {
            // Make sure there is an other field
            if (this.$refs.parent === undefined) { return; }
            if (this.$refs.parent.querySelector('.option--other') === null) { return; }
            
            let parent = this.$refs.parent.querySelector('.option--other');

            if (parent === null) { return; }

            let input = parent.querySelector('.other-activator');
            let other = parent.querySelector('.input--other'); 

            let fields = this.siloData.user.modules[this.field.push_key];


            if (Array.isArray(fields)) {

                // Loop through all the values
                fields.forEach(value => {
                    // If we have a value that doesn't have a field, fill it into other
                    if (this.$refs.parent.querySelector('[value="' + value + '"]') === null) {
                        parent.classList.add('active');
                        input.value = value;
                        input.checked = true;
                        other.value = value;
                        this.$emit('validate');
                    }
                });
            }
        },
    },   
}
</script>

<style lang="scss" scoped>

</style>