<template>
    <div class="badge" :class="active===true ? 'badge--active':'badge--inactive'">
        <div ref="parent" class="badge__wrapper r r--justify-center r--halign-center">
            <button @click="celebrate">
                <Icon :name="badgeName"/>
            </button>
            <span ref="celebrator" class="celebrator"></span>
        </div>
    </div>
</template>

<script>
import Icon from "@c/Icon.vue";
import { emojisplosion, emojisplosions } from "emojisplosion";
export default {
    components: {
        Icon,
    },
    data() {
        return {
            isCelebrating: false,
        }
    },
    props: {
        instance: Object,
        active: Boolean,
    }, 
    computed: {
        badgeName() {
            return `badge/${this.instance.badge}`;
        }
    },
    mounted() {
        this.onActive();
    },
    watch: {
        active() {
            if (this.active === true) {
                this.onActive();
            } else {
                this.$refs.parent.classList.remove('show');
            }
        }
    },
    methods: {
        onActive() {
            setTimeout(() => {
                this.celebrate();
            }, 350);
        },
        celebrate() {
            if (this.active === false) return;
            if (this.isCelebrating === true) return;
            
            this.$refs.parent.classList.add('show');

            // Set flag so you can't keep clicking it
            this.isCelebrating = true;

            setTimeout(() => {
                this.$refs.parent.classList.remove('celebrating');
                setTimeout(() => {
                    this.$refs.parent.classList.add('celebrating');
                }, 1);
                this.explode();

                setTimeout(() => {

                    this.$refs.parent.classList.remove('celebrating');
                    setTimeout(() => {
                        this.$refs.parent.classList.add('celebrating');
                    }, 1);
                    this.explode();

                    setTimeout(() => {

                        this.$refs.parent.classList.remove('celebrating');
                        setTimeout(() => {
                            this.$refs.parent.classList.add('celebrating');
                        }, 1);
                        this.explode();

                        // Allow celebrating again
                        this.isCelebrating = false;
                    }, 1000)
                }, 1000);
            }, 750);

            
        },
        explode() {
            let elem = this.$refs.celebrator;
            let bounds = elem.getBoundingClientRect();
            emojisplosion({
                emojis: ["⭐️"],
                emojiCount: 20,
                position: {
                    x: bounds.left + elem.clientWidth / 2,
                    y: bounds.top + elem.clientHeight / 2,
                },
                process(element) {
                    // Idea came from: https://codepen.io/kizu/pen/PzGadO
                    // Random number between one and 20
                    let i = Math.floor(Math.random() * 20) + 1;
                    let degree = 15 * i;
                    element.style.filter = `hue-rotate(${degree}deg)`;
                },
            });
        }
    }
}
</script>

<style lang="scss">
@import "@s/_mixins.scss";
@import "@s/_vars.scss";
.badge {
    //transition: 2.15s all;
    //opacity: 0;
    svg {
        z-index: 2;
        transition: all 0.5s $easing_outback;
        opacity: 0;
        transform: translateY(40px);
    }

}
.badge--active {
    //opacity: 1;
}
.badge__wrapper {
    &.celebrating {
        svg {
            animation: pop 0.35s $easing_outback;
        }
    }
    &.show {
        svg {
            opacity: 1;
            transform: translateY(0);
        }
    }
}
.celebrator {
    display: block;
    width: 1px;
    height: 1px;
    z-index: 1;
    position: absolute;
    inset: 5px calc(50% - 10px) 100% auto;
}
@keyframes pop {
  0% {
    transform: scale(1) translateY(0);
  }
  50% {
    transform: scale(0.94) translateY(10px);
  }
  100% {
    transform: scale(1) translateY(0);
  }
}
</style>