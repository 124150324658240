<template>
    <div>
        <div>
            <!-- This is going to be part of the pages loaded -->
            <!-- TODO: add an icon selector to the title in text panels -->
            <!--
            <h2 class="header header--icon r r--row-always r--halign-center">
                <Icon name="icon-tools-help"/>
                <span>Get Help</span>
            </h2>
            -->
            <PageLoader :siloData="siloData" :appState="appState" :active="active" :pageId="pageId" />
        </div>
    </div>
</template>

<script>
import PageLoader from "@c/PageLoader.vue";
import Icon from "@c/Icon.vue";
export default {
    components: {
        PageLoader,
        Icon,
    },
    data() {
        return {

        }
    },
    computed: {
        pageId() {
            return parseInt(this.siloData.urls.gethelp);
        }
    },
    props: {
        active: Boolean,
        siloData: Object,
        appState: String,
    },   
    watch: {

    },
    methods: {

    }     
}
</script>

<style lang="scss">
@import "@s/_mixins.scss";
@import "@s/_vars.scss";

</style>