var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "badge",
      class: _vm.active === true ? "badge--active" : "badge--inactive",
    },
    [
      _c(
        "div",
        {
          ref: "parent",
          staticClass: "badge__wrapper r r--justify-center r--halign-center",
        },
        [
          _c(
            "button",
            { on: { click: _vm.celebrate } },
            [_c("Icon", { attrs: { name: _vm.badgeName } })],
            1
          ),
          _vm._v(" "),
          _c("span", { ref: "celebrator", staticClass: "celebrator" }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }