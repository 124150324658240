var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sh v--30" },
    [
      _vm.instance.title || _vm.instance.content
        ? _c("div", { staticClass: "sh__top v--15" }, [
            _vm.instance.title
              ? _c("h2", {
                  staticClass: "sh__title type--h2",
                  domProps: { innerHTML: _vm._s(_vm.instance.title) },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.instance.content
              ? _c("div", {
                  staticClass:
                    "sh__content type--content-links type--content-margins type--content-styles",
                  domProps: { innerHTML: _vm._s(_vm.instance.content) },
                })
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.instance.sections, function (section, index) {
        return _c(
          "div",
          { key: index, staticClass: "sh__section r" },
          [
            _c("ShowHide", {
              attrs: { shTitle: section.title, shContent: section.content },
            }),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }