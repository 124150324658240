var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.siloData.user.id !== 0
    ? _c(
        "div",
        {
          attrs: {
            id: "pageloader",
            "data-slide-direction": _vm.transitionName,
          },
        },
        [
          _c("div", { staticClass: "pageloader" }, [
            _vm.currentPageData
              ? _c(
                  "div",
                  {
                    staticClass: "page",
                    attrs: { "data-active-slide": _vm.activeSlide },
                  },
                  [
                    _c(
                      "TransitionGroup",
                      {
                        staticStyle: { height: "100%" },
                        attrs: { name: _vm.transitionName, tag: "div" },
                      },
                      _vm._l(_vm.currentPageData, function (slide, index) {
                        return _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.activeSlide === slide.slug,
                                expression: "activeSlide === slide.slug",
                              },
                            ],
                            key: slide.ID,
                            staticClass: "page__slide",
                          },
                          [
                            _c("PageSlide", {
                              attrs: {
                                sd: slide,
                                index: index,
                                slides: _vm.currentPageData,
                                siloData: _vm.siloData,
                                appState: _vm.appState,
                                activeSlide: _vm.activeSlide,
                                active:
                                  _vm.activeSlide === slide.slug ? true : false,
                              },
                              on: {
                                home: function ($event) {
                                  return _vm.home()
                                },
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }