var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vt v--25" }, [
    _vm.instance.title || _vm.instance.content
      ? _c("div", { staticClass: "vt__top v--15" }, [
          _vm.instance.title
            ? _c("h2", {
                staticClass: "vt__title type--h2",
                domProps: { innerHTML: _vm._s(_vm.instance.title) },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", {
            staticClass:
              "vt__content type--content-links type--content-margins type--content-styles",
            domProps: { innerHTML: _vm._s(_vm.instance.content) },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { ref: "members", staticClass: "vt__members" },
      _vm._l(_vm.instance.team, function (member, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "vt__member r",
            attrs: { "data-member-name": member.name, "data-watched": "false" },
          },
          [
            _c(
              "button",
              {
                staticClass: "vt__member__link r v--5",
                attrs: { "data-video": member.video },
                on: {
                  click: function ($event) {
                    return _vm.play(member)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "vt__member__image" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "vt__member__image__play r r--justify-center r--halign-center",
                      },
                      [_c("Icon", { attrs: { name: "play-stroke" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("PictureStaticAsset", {
                      attrs: {
                        url_sd: _vm.getImage(member).sd,
                        url_hd: _vm.getImage(member).hd,
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "vt__member__image__bg" }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("h5", { staticClass: "vt__member__name" }, [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(
                        member.name_override
                          ? member.name_override
                          : member.name
                      ),
                    },
                  }),
                ]),
              ]
            ),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "vt__footer r r--justify-center r--halign-center v--30 type--align-center",
      },
      [
        _c("transition", { attrs: { name: "button" } }, [
          _vm.is_valid
            ? _c(
                "button",
                {
                  staticClass: "btn btn--solid",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("next")
                    },
                  },
                },
                [_vm._v("\n                Next >\n            ")]
              )
            : _vm._e(),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }