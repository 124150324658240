<template>
    <div ref="parent" class="plans">
        <div class="text type--content-styles type--content-margins type--content-links v--25">
            <div class="plans__top">
                <h2 class="text__title header icon icon--plan r r--row-always r--halign-center">
                    My Plan
                </h2>
                <p>Here are the plans you created at the end of each session.</p>
            </div>  
            <div class="plans__bottom v--10">

                <!-- Module 1 START -->
                <ShowHideSlot v-if="siloData.user.progress[0].status === 'complete'">
                    <template #title>
                        My Time to Quit
                    </template>
                    <template #content>
                        <p><strong>Your Quit Date Plan:</strong></p>
                        <p>Your Motivations to Quit:</p>
                        <ul>
                            <li v-for="(item, index) in siloData.user.modules.meta_m1_q1" :key="index" v-text="item"></li>
                        </ul>
                        <p>Your Ally:</p>
                        <p>{{ siloData.user.modules.meta_m1_q8 }}</p>
                        <p>Your Quit Tips:</p>
                        <ul>
                            <li v-for="(item, index) in siloData.user.modules.meta_m1_q10" :key="index" v-text="item"></li>
                        </ul>
                    </template>
                </ShowHideSlot>
                <!-- Module 1 END -->

                <!-- Module 2 START -->
                <ShowHideSlot v-if="siloData.user.progress[1].status === 'complete'">
                    <template #title>
                        Cope When I Crave
                    </template>
                    <template #content>
                        <p><strong>Your Coping Strategies Plan:</strong></p>
                        <p>Your Trigger:</p>
                        <p>{{ siloData.user.modules.meta_m2_q7[0] }}</p>
                        <p>Your Coping Strategies:</p>
                        <ul>
                            <li v-for="(item, index) in siloData.user.modules.meta_m2_q8" :key="index" v-text="item"></li>
                        </ul>
                    </template>
                </ShowHideSlot>
                <!-- Module 2 END -->

                <!-- Module 3 START -->
                <ShowHideSlot v-if="siloData.user.progress[2].status === 'complete'">
                    <template #title>
                        I Won't Vape
                    </template>
                    <template #content>
                        <p><strong>Your Refusal Skills Plan:</strong></p>
                        <p>Person:</p>
                        <p>{{ siloData.user.modules.meta_m3_q8 }}</p>
                        <p>Situation:</p>
                        <p>{{ siloData.user.modules.meta_m3_q9 }}</p>
                        <p>Assertive Responses:</p>
                        <ul>
                            <li>{{siloData.user.modules.meta_m3_q10}}</li>
                            <li>{{siloData.user.modules.meta_m3_q11}}</li>
                        </ul>
                    </template>
                </ShowHideSlot>
                <!-- Module 3 END -->

                <!-- Module 4 START -->
                <ShowHideSlot v-if="siloData.user.progress[3].status === 'complete'">
                    <template #title>
                        Manage My Stress
                    </template>
                    <template #content>
                        <p><strong>Your Deal With Stress Plan:</strong></p>
                        <p>Your Stressors:</p>
                        <ul>
                            <li v-for="(item, index) in siloData.user.modules.meta_m4_q5" :key="index" v-text="item"></li>
                        </ul>
                        <p>What can you do?:</p>
                        <ul>
                            <li v-for="(item, index) in siloData.user.modules.meta_m4_q7" :key="index" v-text="item"></li>
                        </ul>
                    </template>
                </ShowHideSlot>
                <!-- Module 4 END -->

                <!-- Module 5 START -->
                <ShowHideSlot v-if="siloData.user.progress[4].status === 'complete'">
                    <template #title>
                        I Won't React
                    </template>
                    <template #content>
                        <p><strong>Your Negative Mood Plan:</strong></p>
                        <p>Your Triggers:</p>
                        <ul>
                            <li v-for="(item, index) in siloData.user.modules.meta_m5_q4" :key="index" v-text="item"></li>
                        </ul>
                        <p>What can you say to yourself?:</p>
                        <ul>
                            <li v-for="(item, index) in siloData.user.modules.meta_m5_q5" :key="index" v-text="item"></li>
                        </ul>
                    </template>
                </ShowHideSlot>
                <!-- Module 5 END -->

                <!-- Module 6 START -->
                <ShowHideSlot v-if="siloData.user.progress[5].status === 'complete'">
                    <template #title>
                        I'll Think Then Act
                    </template>
                    <template #content>
                        <p><strong>Your Your Stop to Think Plan:</strong></p>
                        <p>Your Routine or Habit</p>
                        <p>Situation</p>
                        <p>
                            {{siloData.user.modules.meta_m6_q4}}
                        </p>
                        <p>Stop to Think</p>
                        <ul>
                            <li v-for="(item, index) in siloData.user.modules.meta_m6_q8" :key="index" v-text="item"></li>
                        </ul>
                        <p>In The Moment</p>
                        <p>Situation</p>
                        <p>
                            {{siloData.user.modules.meta_m6_q5}}
                        </p>
                        <p>Stop to Think</p>
                        <ul>
                            <li v-for="(item, index) in siloData.user.modules.meta_m6_q9" :key="index" v-text="item"></li>
                        </ul>

                    </template>
                </ShowHideSlot>
                <!-- Module 6 END -->

                <!-- Module 7 START -->
                <ShowHideSlot v-if="siloData.user.progress[6].status === 'complete'">
                    <template #title>
                        I Commit to Quit
                    </template>
                    <template #content>
                        <p><strong>Your Plan for the Future:</strong></p>
                        <p>Your Triggers:</p>
                        <ul>
                            <li v-for="(item, index) in siloData.user.modules.meta_m7_q9" :key="index" v-text="item"></li>
                        </ul>
                        <p>Your Coping Strategies:</p>
                        <ul>
                            <li v-for="(item, index) in siloData.user.modules.meta_m7_q10" :key="index" v-text="item"></li>
                        </ul>
                        <p>Your Challenge to Red Flag Thoughts:</p>
                        <ul>
                            <li v-for="(item, index) in siloData.user.modules.meta_m7_q8" :key="index" v-text="item"></li>
                        </ul>
                    </template>
                </ShowHideSlot>
                <!-- Module 7 END -->



            </div>  
        </div>
    </div>
</template>


<script>
import Icon from "@c/Icon.vue";
import ShowHideSlot from "@c/elements/ShowHideSlot.vue";
export default {
    components: {
        Icon,
        ShowHideSlot,
    },
    props: {
        active: Boolean,
        siloData: Object,
        appState: String,
    },
    watch: {
        active() {
            if (this.active === true) {
                this.postPageView();
            }
        }
    },
    methods: {
        postPageView() {
            let data = {
                type: 'plan',
                title: 'My Plan',
            };
            window.events.postPageView(data);
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@s/_mixins.scss";
@import "@s/_vars.scss";
.plans {
    padding-bottom: rem(90px);
}
</style>