var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.active
    ? _c("span", { staticStyle: { display: "none" }, attrs: { id: "wm" } }, [
        _c("div", { ref: "wm", staticClass: "wm v--40" }, [
          _c(
            "div",
            { staticClass: "wm__top v--10" },
            [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "TransitionGroup",
                { attrs: { name: "fade", tag: "div" } },
                _vm._l(_vm.quotes, function (quote, index) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currentQuote === index,
                          expression: "(currentQuote === index)",
                        },
                      ],
                      key: index,
                      staticClass: "quote",
                    },
                    [
                      _c("h1", {
                        staticClass: "quote__text type--color--purple",
                        domProps: { innerHTML: _vm._s(quote) },
                      }),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _vm.quotes.length > 1
                ? _c("div", { staticClass: "wm__count" }, [
                    _c("span", {
                      staticClass: "wm__count__current",
                      domProps: { textContent: _vm._s(_vm.currentQuote + 1) },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "wm__count__sep" }, [
                      _vm._v("of"),
                    ]),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "wm__count__full",
                      domProps: { textContent: _vm._s(_vm.quotes.length) },
                    }),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "wm__nav r r--justify-center r--halign-center" },
            [
              _c("button", {
                staticClass: "btn btn--solid",
                domProps: {
                  textContent: _vm._s(
                    _vm.currentQuote === _vm.quotes.length - 1
                      ? "Close"
                      : "Next"
                  ),
                },
                on: { click: _vm.next },
              }),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wm__tag" }, [
      _c("span", [_vm._v("Welcome Back!")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }