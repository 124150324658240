<template>
    <div class="session type--align-center v--20">
        <!--{{instance}}-->
        <div v-if="instance.icon !== null && instance.icon !== ''" class="session__icon">
            <IconSession :name="instance.icon"/>
        </div>
        <h1 class="session__title type--h1--session" v-html="instance.title"/>
    </div>
</template>

<script>
import IconSession from "@c/IconSession.vue"
export default {
    components: {
        IconSession,
    },
    computed: {
        sessionIcon() {
            return "session/" + this.instance.icon;
        }
    },
    props: {
        instance: Object,
    },
}
</script>

<style lang="scss">
@import "@s/_mixins.scss";
@import "@s/_vars.scss";
.session__icon {
    margin-top: rem(70px);
}
.session__title {
    padding-bottom: rem(20px);
}
</style>