<template>
    <div class="img">
        <img :src="url_hd"/>
    </div>
</template>

<script>
const axios = require('axios');
export default {
    props: {
        url_sd: String,
        url_hd: String,
    },
}
</script>

<style lang="scss" scoped>

</style>