var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "variable",
      class: `variable--${_vm.instance.type} variant--${_vm.instance.variant}`,
      attrs: { "data-field": _vm.instance.field },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "variable__response type--content-styles type--content-margins type--content-links v--20",
        },
        [
          _vm.instance.header
            ? _c("div", { staticClass: "variable__response__header" }, [
                _c("div", {
                  class: _vm.instance.header_type,
                  domProps: { textContent: _vm._s(_vm.instance.header) },
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "variable__response__text" }, [
            _vm.instance.type === "default"
              ? _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.formatAs(_vm.answer)) },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.instance.type === "list"
              ? _c(
                  "ul",
                  { class: `list--${_vm.instance.list_style}` },
                  _vm._l(_vm.answer, function (item) {
                    return _c("li", { key: item.id }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.formatAs(item)) +
                          "\n                "
                      ),
                    ])
                  }),
                  0
                )
              : _vm._e(),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }