<template>
	<span v-if="active" id="cm" style="display: none;">
		<div ref="cm" class="cm v--40">
			<div class="cm__top v--10">
				<div class="cm__tag">
					<span>Great Job!</span>
				</div>
				<h1 class="quote__text type--color--purple">
					Get craving tips, slip help, and access to the Vape Fee Tracker from the toolbar at the bottom of the homescreen.
				</h1>
			</div>
			<div class="cm__nav r r--justify-center r--halign-center">
				<button class="btn btn--solid" @click="close">Close</button>
			</div>
		</div>
	</span>
</template>

<script>
	const axios = require('axios');
	export default {
		props: {
			appState: String,
			siloData: Object,
            active: Boolean,
		},
		data() {
			return {
				hasSeen: false,
				currentQuote: 0,
				quotes: [],
				modalArgs: {
					button: false,
					message_is_element: true,
					message: '.cm',
					additional_classes:'notice-centered',
					onClose: this.onClose,
				},
				messages: {

				}
			}
		},
		watch: {
			active() {
				console.log("completion modal, watch active");
				if (this.active === true) {
					console.log("completion modal, watch active is true");
					this.check();
				}
			},
		},
		mounted() {

		},
		methods: {
			// Check to see if we open the modal or not
			check() {
                console.log('module complete active check');
				// This doesn't work because it loads up home first
				// Would need to figure out some kind of delayed loading
				
				//if (this.hasQueryString() === false) { return; }
				if (this.active === false) { return; }
				// if (this.hasSeen === true) { return; }
				// console.log("welcome modal, check passed");
				
				// this.hasSeen = true;
				this.open();
			},
			// Check to see if the logged-in=true query string exists
			// This doesn't work if the user goes through the login flow pages
			hasQueryString() {
				let urlParams = new URLSearchParams(window.location.search);
				let returned = false;

				if (urlParams.has('module-complete')) {
					if (urlParams.get('module-complete') === 'true') {
						returned = true;
					}
				}

				return returned;
			},
			// Open the modal
			open() {
                console.log('module complete opening');
				// console.log('opening modal');
				// console.log("welcome modal, open");

				setTimeout(() => {
					modal.set(this.modalArgs);
				}, 500);
			},
            close() {
                modal.close();
                this.$emit('moduleCompleteReset');
            },
			onClose() {
				console.log('completion modal on close');
				this.$emit('moduleCompleteReset');
			},
		},
	}
</script>

<style lang="scss" scoped>
@import "@s/_mixins.scss";
@import "@s/_vars.scss";
.cm__tag {
	> * {
		font-size: rem(14px);
		line-height: lh(14px, 18px);
		letter-spacing: 0.5px;
		font-weight: $weight_bold;
		text-transform: uppercase;
	}
}
.quote__text {
	font-size: rem(28px);
	line-height: lh(28px, 34px);
	letter-spacing: 0.34px;
	font-weight: $weight_bold;
}

</style>