var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "input input--text v--10" }, [
    _vm.field.field_label
      ? _c("label", {
          class: _vm.hideLabel,
          domProps: { innerHTML: _vm._s(_vm.field.field_label) },
        })
      : _vm._e(),
    _vm._v(" "),
    _c("input", {
      attrs: {
        type: "text",
        "data-push-key": _vm.field.push_key,
        placeholder: _vm.field.field_placeholder,
        maxlength: _vm.field.max_chars,
        "data-watch-event": "keyup",
        "data-pristine-not-blank": "",
        required: "",
      },
      domProps: { value: _vm.siloData.user.modules[_vm.field.push_key] },
      on: {
        keyup: function ($event) {
          return _vm.$emit("validate")
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }