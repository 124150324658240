<template>
    <div ref="parent" class="form-panel v--30">
        <!-- {{instance}} -->

        <div v-if="instance.title || instance.content" class="form-panel__top v--15">
            <h2 v-if="instance.title" class="form-panel__title type--h2" v-html="instance.title"></h2>
            <div v-if="instance.content" class="form-panel__content type--content-links type--content-margins type--content-styles" v-html="instance.content"></div>
        </div>

        <!-- instance image is not in here yet -->
        <div v-if="instance.image !== 0" class="text__content__image"></div>
        
        <form 
            ref="form"
            action="" 
            method="post" 
            :data-type="type" 
            :data-on-submit-url="on_submit_url" 
            class="form form--v required v--50">

            <div class="form__fields r v--30">
                <!-- have to loop through fields here, will probably do components-->
                <div v-for="(field, index) in instance.form.widgets" :key="index" class="input input--buttons v--25">
                    <Buttons v-if="field.panels_info.class === 'Form_Buttons'" :field="field" :siloData="siloData" @validate="validate"/>
                    <CheckBoxes v-if="field.panels_info.class === 'Form_Checkboxes'" :field="field" :siloData="siloData" @validate="validate"/>
                    <Date v-if="field.panels_info.class === 'Form_Date'" :field="field" :siloData="siloData" @validate="validate"/>
                    <TextField v-if="field.panels_info.class === 'Field_Text'" :field="field" :siloData="siloData" @validate="validate"/>
                    <TextAreaField v-if="field.panels_info.class === 'Field_Textarea'" :field="field" :siloData="siloData" @validate="validate"/>
                    <Likert v-if="field.panels_info.class === 'Form_Likert'" :field="field" :siloData="siloData" @validate="validate"/>
                </div>
            </div>
            <div v-for="(field, index) in instance.hidden_fields" :key="index">
                <input 
                    type="hidden"
                    :name="field.key"
                    :data-push-key="field.key"
                    :data-meta-key="field.key"
                    :value="field.value"
                    required>
            </div>
            <div v-if="instance.post_form_content" class="form__pfc r type--content-styles type--content-margins" v-html="instance.post_form_content"></div>
            <div class="form__footer r r--justify-center r--halign-center v--30 type--align-center">
                <transition name="button">
                    <button 
                        v-if="is_valid"
                        class="form__submit btn btn--solid" 
                        @click.prevent="submit"
                        v-html="instance.button_text ? instance.button_text : 'Next >'">
                    </button>
                </transition>
            </div>
        </form>
    </div>
</template>

<script>
import Buttons from "@c/panels/form/Buttons.vue";
import CheckBoxes from "@c/panels/form/CheckBoxes.vue";
import Date from "@c/panels/form/Date.vue";
import TextField from "@c/panels/form/TextField.vue";
import TextAreaField from "@c/panels/form/TextAreaField.vue";
import Likert from "@c/panels/form/Likert.vue";

const pristine = require("pristinejs");
const axios = require('axios');

export default {
    components: {
        Buttons,
        CheckBoxes,
        Date,
        TextField,
        TextAreaField,
        Likert,
    },
    data() {
        return {
            pristine: null,
            validation_config: {
                // class of the parent element where the error/success class is added
                classTo: 'input',
                errorClass: 'input--error',
                successClass: 'input--success',
                // class of the parent element where error text element is appended
                errorTextParent: 'input',
                // type of element to create for the error text
                errorTextTag: 'div',
                // class of the error text element
                errorTextClass: 'input__help'
            },
            type: this.instance.type,
            on_submit_url: this.instance.on_submit_url,
            inputs: null,
            is_valid: false, // may want to make this mor ambiguous at some point
        }
    },
    props: {
        instance: Object,
        siloData: Object,
    },
    computed: {
        formId() {
            return this.instance.panels_info.widget_id;
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.assign();
        });
    },
    watch: {
        siloData() {
            this.$nextTick(() => {
                this.assign();
            });
        }
    },
    destroyed(){
        // console.log('Form Destroyed!');
        this.inputs = null;
        this.pristine = null;
        this.is_valid = false;
    },
    methods: {
        assign() {
            // This only grabs required inputs
            this.inputs = this.$refs['parent'].querySelectorAll('[required]');
            //this.inputs_optional = this.$refs['parent'].querySelectorAll('[optional]');
            this.pristine = new pristine(this.$refs['form'], this.validation_config, true);
            // The parent is mounted after all the children are mounted
            this.validate();
        },
        validate() {
            this.inputs = this.$refs['parent'].querySelectorAll('[required]');
            this.is_valid = this.pristine.validate(this.inputs);
        },
        // Validate fields without letting them know
        validateQuiet() {
            this.inputs = this.$refs['parent'].querySelectorAll('[required]');
            this.is_valid = this.pristine.validate(this.inputs, true);
        },
        submit() {
            if (!this.is_valid) return;

            if (this.$refs.parent.querySelector('.likert') !== null) {
                console.log('A likert has been found!');
                this.submitLikert();
                return; // stop whatever we are doing here
            }

            let data = [];

            // Loop through required items
            this.inputs.forEach(input => {
                // If it's not checked don't pass it up
                if (input.type === "radio" && input.checked == false) { return; }
                if (input.type === "checkbox" && input.checked == false) { return; }

                let key = input.dataset.pushKey;
                let value = input.value;

                // Don't allow passing up of blank data
                if (value === "") { return; }

                let data_item = {
                    key,
                    value
                }
                data.push(data_item);
            });

            //console.log("FORMS: DATA", data);

            // set processing
            this.$emit('processing', true);

            axios.post(this.siloData.urls.postuserdata, data).then(response => {
                // console.log(response);
                // If we get it, tell the app to refresh the silo data
                // Then complete the form page and move to the next
                
                // Pass data object to post submit data event
                this.postSubmitData(data);
                
                // Turn off processing
                // this.$emit('processing', false);
                let onComplete = this.$emit('next');
                // When the data processing is complete, fire next
                this.$emit('refreshData', onComplete);
            }).catch(err => {
                console.log(err);
                // Turn off processing
                this.$emit('processing', false);
            });

        },
        submitLikert() {
            console.log('doing a submit likert instead of a regular submit');

            // Set up data object
            let data = {
                key: this.inputs[0].dataset.pushKey, // Get push key from first input
                data: [], // Fill this later
            };

            // Loop through required items
            this.inputs.forEach(input => {
                // If it's not checked don't pass it up
                if (input.type === "radio" && input.checked == false) { return; }

                let labelValue = input.dataset.label;
                let ratingValue = input.value;

                // Don't allow passing up of blank data
                if (labelValue === "" || ratingValue === "") { return; }

                let data_item = {
                    label: labelValue,
                    rating: ratingValue,
                }
                data.data.push(data_item);
            });

            console.log(data);

            // set processing
            this.$emit('processing', true);

            axios.post(this.siloData.urls.postlikert, data).then(response => {
                console.log(response);
                // If we get it, tell the app to refresh the silo data
                // Then complete the form page and move to the next
                
                // Pass data object to post submit data event
                this.postSubmitData(data);
                
                // Turn off processing
                // this.$emit('processing', false);
                let onComplete = this.$emit('next');
                // When the data processing is complete, fire next
                this.$emit('refreshData', onComplete);
            }).catch(err => {
                console.log(err);
                // Turn off processing
                this.$emit('processing', false);
            });
        },
        // Pass a key and an array to remove duplicates
        removeDuplicates(arr, key) {
            var values = {};
            return arr.filter(function (item) {
                var val = item[key];
                var exists = values[val];
                values[val] = true;
                return !exists;
            });
        },
        // Send data to the event tracker to post to a custom event
        postSubmitData(formData) {
            let data = {
                type: 'submit',
                data: formData,
            };
            window.events.postSubmitData(data);
        },
    }, 
}
</script>

<style lang="scss">
@import "@s/_mixins.scss";
@import "@s/_vars.scss";
.form-panel {
    transition: all 0.15s;
}
[data-processing="true"] {
    .form-panel {
        opacity:0.25;
        button {
            pointer-events: none;
        }
        input {
            pointer-events: none;
        }
    }
}
.button-enter, .button-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
</style>