<template>
    <div class="icon-session">
        <div
            v-for="(ic, index) in icons"
            :key="index"
            v-if="ic === name"
            v-html="require(`!!html-loader!@icons/session/${ic}.svg`).default" 
            class="fill-current"
        ></div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                icons: [
                    'module-1-check-in',
                    'module-1-facts',
                    'module-1-plan',
                    'module-1-ready',
                    'module-1-summary',
                    'module-1-team',
                    'module-2-check-in',
                    'module-2-coping',
                    'module-2-plan',
                    'module-2-summary',
                    'module-2-triggers',
                    'module-2-withdrawal',
                    'module-3-check-in',
                    'module-3-plan',
                    'module-3-refusal',
                    'module-3-summary',
                    'module-4-check-in',
                    'module-4-plan',
                    'module-4-stress',
                    'module-4-summary',
                    'module-5-check-in',
                    'module-5-moods',
                    'module-5-plan',
                    'module-5-summary',
                    'module-6-check-in',
                    'module-6-decision-1',
                    'module-6-decision-2',
                    'module-6-plan',
                    'module-6-summary',
                    'module-7-check-in',
                    'module-7-future',
                    'module-7-plan',
                    'module-7-summary',
                ]
            }
        },
        props: {
            name: String,
        },
        mounted() {

        },
    }
</script>