<template>
    <div class="vt v--25">
        <!-- {{instance}} -->
        <div v-if="instance.title || instance.content" class="vt__top v--15">
            <h2 v-if="instance.title" v-html="instance.title" class="vt__title type--h2"></h2>
            <div class="vt__content type--content-links type--content-margins type--content-styles" v-html="instance.content"></div>
        </div>
        <div class="vt__members" ref="members">
            <div v-for="(member, index) in instance.team" :key="index" class="vt__member r" :data-member-name="member.name" data-watched="false">
                <!-- {{member}} -->
                <button class="vt__member__link r v--5" :data-video="member.video" @click="play(member)">
                    <div class="vt__member__image">
                        <div class="vt__member__image__play r r--justify-center r--halign-center">
                            <Icon name="play-stroke" />
                        </div>
                        <PictureStaticAsset :url_sd="getImage(member).sd" :url_hd="getImage(member).hd" />
                        <div class="vt__member__image__bg"></div>
                    </div>
                    <h5 class="vt__member__name">
                        <span v-text="member.name_override ? member.name_override : member.name"></span>
                    </h5>
                </button>
            </div>
        </div>
        <div class="vt__footer r r--justify-center r--halign-center v--30 type--align-center">
            <transition name="button">
                <button v-if="is_valid" class="btn btn--solid" @click="$emit('next')">
                    Next >
                </button>
            </transition>
        </div>
    </div>
</template>

<script>
import PictureStaticAsset from "@c/PictureStaticAsset.vue";
import Icon from "@c/Icon.vue";
export default {
    components: {
        PictureStaticAsset,
        Icon,
    },
    data() {
        return {
            watched: 0,
            //required: this.instance.required_count ? this.instance.required_count : 0,
            is_valid: false,
        }
    },
    computed: {
        required() {
            return this.instance.required_count ? parseInt(this.instance.required_count) : 0;
        }
    },
    props: {
        instance: Object,
        siloData: Object,
        active: Boolean,
    },
    mounted() {
        this.reset();
        this.validate();
    },
    watch: {
        active() {
            if (this.active === true) {
                this.reset();
            }
        }
    },
    methods: {
        // validate watched count, this only counts for one watch at this time
        validate() {
            if (this.watched >= this.required) {
                this.is_valid = true;
            } else {
                this.is_valud = false;
            }
        },
        play(member) {

            let parent = this.$refs.members.querySelector(`[data-member-name="${member.name}"]`);

            // Remove https becuase it screws up subsequent video loads
            let video_url = member.video.replace('https:', '');
            
            // fire the video modal
            window.videoPlayer.playVideoOnClick('vimeo', member.video);

            // If this has already been watched, we don't need to validate it
            if (parent.dataset.watched === "true") return;

            this.postVideoWatched(member);

            // Add some style to this specific one
            parent.dataset.watched = "true";

            // Increase watched count
            this.watched++;

            // validate to see if we can move on
            this.validate();
        },
        getImage(member) {
            let name = member.name.toLowerCase();
            return { 
                "sd":this.siloData.images[name].person.sd[0] ? this.siloData.images[name].person.sd[0] : "",
                "hd":this.siloData.images[name].person.hd[0] ? this.siloData.images[name].person.hd[0] : "",
            };
        },
        reset() {
            this.watched = 0;
            this.is_valid = false;
            this.$refs.members.querySelectorAll('.vt__member').forEach(member => {
                member.dataset.watched = "false";
            });
        },
        postVideoWatched(member) {
            let data = {
                type: 'watched',
                title: member.video,
            };
            window.events.postAction(data);
        },
    }
}
</script>

<style lang="scss">
@import "@s/_mixins.scss";
@import "@s/_vars.scss";
.vt__members {
    padding-top: rem(30px);
    display: grid;
    grid-template-columns: rem(100px) rem(100px);
    grid-template-rows: auto;
    column-gap: rem(35px);
    row-gap: rem(20px);
    justify-content: center;
}
.vt__member {
    transition: all 0.15s;
    &[data-watched="true"] {
        opacity: 0.5;
    }
}
.vt__member__name {
    font-size: rem(15px);
    line-height: lh(15px, 18px);
    font-weight: $weight_bold;
    letter-spacing: 0.58px;
    text-align: center;
    width: 100%;
    span {
        text-align: center;
    }
}
.vt__member__image {
    z-index: 1;
    width: rem(100px);
    height: rem(100px);
    display: flex;
    justify-content: center;
    align-items: center;
}
.vt__member__image {
    .img, img {
        border-radius: 100%;
        z-index: 2;
    }
    .img {
        width: rem(90px);
        height: rem(90px);
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.vt__member__image__play {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.vt__member__image__bg {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom:0;
    z-index: 1;
}
.vt__footer {
    margin-top: rem(30px);
}
</style>